import AsyncStorage from '@react-native-async-storage/async-storage'
import React, { ErrorInfo, PropsWithChildren } from 'react'
import { ScrollView, Text, View } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { isBoogooApiError, isKeepApiError } from '../api'
import AppRestartButton from './app_restart_button'

export class ErrorBoundary extends React.Component<PropsWithChildren> {
  state = {} as
    | Record<string, never>
    | {
        error: Error
        errorInfo: ErrorInfo
      }

  static getDerivedStateFromError(error: Error) {
    return { error }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({ error, errorInfo })
  }

  onRestart = async () => {
    const should_clear_storage =
      (isKeepApiError(this.state.error) && this.state.error.is_login_error) ||
      (isBoogooApiError(this.state.error) && this.state.error.is_login_error)
    if (should_clear_storage) {
      await AsyncStorage.clear()
    }
  }

  render() {
    const { error } = this.state
    if (!error) return this.props.children
    return (
      <SafeAreaScrollView>
        <View
          style={{ padding: 16, borderRadius: 8, backgroundColor: '#FFFFFF' }}
        >
          <Text
            style={{
              fontSize: 16,
              lineHeight: 24,
              fontWeight: '400',
              color: '#222222',
              textAlign: 'center',
            }}
          >
            {error.message}
          </Text>
        </View>
        <AppRestartButton onRestart={this.onRestart} />
      </SafeAreaScrollView>
    )
  }
}

const SafeAreaScrollView = (props: PropsWithChildren) => {
  return (
    <ScrollView style={{ backgroundColor: '#F5F6F8' }}>
      <SafeAreaView style={{ padding: 20, gap: 20 }}>
        {props.children}
      </SafeAreaView>
    </ScrollView>
  )
}
