export const get = async (url: string, fileType: 'pdf' | 'png') => {
  return new Promise((resolve, reject) => {
    resolve(url)
  })
  // return fetch(url)
  //   .then((response) => (response as any).blob())
  //   .then((blob) => {
  //     const reader = new FileReader()
  //     reader.readAsDataURL(blob)
  //     return new Promise((res) => {
  //       reader.onloadend = () => {
  //         console.log(reader.result)
  //         let result =
  //           fileType === 'pdf'
  //             ? 'data:application/pdf;base64,'
  //             : 'data:image/png;base64,'
  //
  //         result = result + reader.result
  //
  //         res(reader.result)
  //       }
  //     })
  //   })
}
