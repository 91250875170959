export const camera = async () => {
  return 'unavailable'
}

export const contacts = async () => {
  return 'unavailable'
}

export const storage = async () => {
  return 'unavailable'
}

export const fine_location = async () => {
  return 'unavailable'
}

export const coarse_location = async () => {
  return 'unavailable'
}

export const requestLocation = async () => {
  return 'unavailable'
}
