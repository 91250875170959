import { platform } from '@pay24/common'
import AsyncStorage from '@react-native-async-storage/async-storage'
import axios, { type AxiosResponse } from 'axios'
import type { SnapshotIn } from 'mobx-state-tree'
import { stringify } from 'query-string'
import { Platform } from 'react-native'
import { Company } from './store/erp/models'

declare module 'axios' {
  export interface AxiosRequestConfig {
    signal?: AbortSignal
  }
}

const PASSPORT_URL = platform.passport.url + '/api'
const WALLET_URL = platform.wallet.url

const BOOGOO_URL = platform.boogoo.url
const KEEP_URL = platform.erp.url

const paramsSerializer = (params: any) => {
  if (!params) return stringify({ ...params })
  if (!params.filter || typeof params.filter !== 'object')
    return stringify({ ...params })
  const { filter, ...rest } = params
  const filter_params = Object.entries(filter).reduce(
    (_filter, [key, value]) => {
      if (typeof value === 'string') {
        if (value.trim().length > 0) _filter[`filter[${key}]`] = value
      } else if (typeof value === 'number') {
        _filter[`filter[${key}]`] = value
      } else if (typeof value === 'boolean') {
        _filter[`filter[${key}]`] = value
      } else if (Array.isArray(value)) {
        if (value.length > 0) _filter[`filter[${key}]`] = value.join(',')
      }
      return _filter
    },
    {},
  )
  return stringify({ ...rest, ...filter_params })
}

export const passportApi = axios.create({ baseURL: PASSPORT_URL })
export const walletApi = axios.create({ baseURL: WALLET_URL })
export const boogooApi = axios.create({ baseURL: BOOGOO_URL })
export const keepApi = axios.create({ baseURL: KEEP_URL, paramsSerializer })

walletApi.interceptors.request.use(async (value) => {
  if (value.signal && !value.cancelToken) {
    const cancelTokenSource = axios.CancelToken.source()
    value.signal.addEventListener('abort', () => cancelTokenSource.cancel())
    value.cancelToken = cancelTokenSource.token
  }
  return value
})

walletApi.interceptors.response.use((value) => {
  if (typeof value.data.result === 'number' && value.data.result !== 0)
    throw new Error(value.data.message || value.statusText)
  return value
})

const accessToken = async () => {
  try {
    const login_response = await AsyncStorage.getItem('login-response')
    if (login_response) {
      const { token } = JSON.parse(login_response) as LoginResponse
      return token
    }
  } catch {}
  return null
}

const BOOGOO_ERROR_CODES = {
  USER_NOT_AUTHORIZED: 1,
  USER_BLOCKED: 5,
} as const

class BoogooApiError extends Error {
  readonly response: AxiosResponse
  constructor(response: AxiosResponse) {
    const message = response.data.message || response.statusText
    super(message)
    this.response = response
  }

  get is_login_error() {
    return (
      this.response.data.result === BOOGOO_ERROR_CODES.USER_NOT_AUTHORIZED ||
      this.response.data.result === BOOGOO_ERROR_CODES.USER_BLOCKED
    )
  }
}

boogooApi.interceptors.request.use(async (value) => {
  if (value.signal && !value.cancelToken) {
    const cancelTokenSource = axios.CancelToken.source()
    value.signal.addEventListener('abort', () => cancelTokenSource.cancel())
    value.cancelToken = cancelTokenSource.token
  }
  return value
})

boogooApi.interceptors.request.use(async (value) => {
  const token = await accessToken()
  if (token) value.headers.Authorization = `Bearer ${token}`
  return value
})

boogooApi.interceptors.response.use((value) => {
  if (typeof value.data.result === 'number' && value.data.result !== 0)
    throw new BoogooApiError(value)
  return value
})

export const isBoogooApiError = (error: any): error is BoogooApiError =>
  error instanceof BoogooApiError

const companyId = async () => {
  try {
    const id = await AsyncStorage.getItem('company-id')
    if (id) {
      return parseInt(id)
    }
  } catch {}
  return null
}

const KEEP_ERROR_CODES = {
  USER_NOT_AUTHORIZED: 1,
  USER_BLOCKED: 5,
} as const

class KeepApiError extends Error {
  readonly response: AxiosResponse
  constructor(response: AxiosResponse) {
    const message = response.data.message || response.statusText
    super(message)
    this.response = response
  }

  get is_login_error() {
    return (
      this.response.data.result === KEEP_ERROR_CODES.USER_NOT_AUTHORIZED ||
      this.response.data.result === KEEP_ERROR_CODES.USER_BLOCKED
    )
  }
}

keepApi.interceptors.request.use(async (value) => {
  if (value.signal && !value.cancelToken) {
    const cancelTokenSource = axios.CancelToken.source()
    value.signal.addEventListener('abort', () => cancelTokenSource.cancel())
    value.cancelToken = cancelTokenSource.token
  }
  return value
})

keepApi.interceptors.request.use(async (value) => {
  const token = await accessToken()
  const company_id = await companyId()
  if (token) value.headers.Authorization = `Bearer ${token}`
  if (company_id) value.headers['X-Company'] = company_id
  return value
})

keepApi.interceptors.response.use((value) => {
  if (typeof value.data.result === 'number' && value.data.result !== 0)
    throw new KeepApiError(value)
  return value
})

export const isKeepApiError = (error: any): error is KeepApiError =>
  error instanceof KeepApiError

export const fetchCountryCode = async (signal: AbortSignal) => {
  const { token, cancel } = axios.CancelToken.source()
  signal.addEventListener('abort', () => cancel())
  await passportApi.get('/country', {
    params: { service: 'passport', client: 1 },
    cancelToken: token,
  })
  return 'KG'
}

type LoginResponse = {
  token: string
  user: { phone_number: string; id: number }
  result: 0
}

export const login = async (payload: {
  phone_number: string
  code: string
  token: string
}) => {
  const { data } = await boogooApi.post('/api/v1/auth/login', {
    ...payload,
    platform: Platform.OS,
  })
  await AsyncStorage.setItem('login-response', JSON.stringify(data))
  return data as LoginResponse
}

export const fetchCompanies = async () => {
  const { data } = await boogooApi.get('/api/v1/keep/companies')
  return data.list as SnapshotIn<typeof Company>[]
}

export const fetchCompany = async (id: number, signal: AbortSignal) => {
  const { data } = await boogooApi.get(`/api/v1/keep/companies/${id}`, {
    signal,
  })
  return data.item as SnapshotIn<typeof Company>
}

export const saveCompany = async (
  company: Omit<SnapshotIn<typeof Company>, 'user'>,
) => {
  const { data } = await boogooApi.post('/api/v1/keep/companies', company)
  return data.item as SnapshotIn<typeof Company>
}

export const createCompany = async (
  company: Omit<SnapshotIn<typeof Company>, 'id' | 'user'> & {
    id?: number | null
  },
  token: string,
) => {
  const { data } = await boogooApi.post('/api/v1/keep/companies', company, {
    params: { token, platform: Platform.OS },
  })
  return data.item as SnapshotIn<typeof Company>
}

export const getCompany = async (signal: AbortSignal) => {
  const { token, cancel } = axios.CancelToken.source()
  signal.addEventListener('abort', () => cancel())
  const { data } = await keepApi.get('/api/v1/keep24/settings/company', {
    cancelToken: token,
  })
  return data.item
}

export const sign = async (doc) => {
  const { data } = await keepApi.post(
    '/api/v1/keep24/cds/get-sign/for-hash',
    doc,
  )
  return data.response
}

export const cdsGetPinCode = async () => {
  const { data } = await keepApi.post('/api/v1/keep24/cds/get-pin-code')
  return data.response
}

export const cdsAuthMethods = async () => {
  const { data } = await keepApi.get('/api/v1/keep24/cds/user-auth-methods')
  return data.response
}

export const cdsChangeAuthMethod = async (method) => {
  const { data } = await keepApi.post(
    '/api/v1/keep24/cds/change-user-auth-methods',
    method,
  )
  return data.response
}

export const cdsSendCode = async (code) => {
  const { data } = await keepApi.post('/api/v1/keep24/cds/auth', code)
  return data.response
}

export const saveNotificationToken = async (body) => {
  await boogooApi.post('/api/v1/push/register', body)
}
