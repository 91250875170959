import React from 'react'
import styles from '../assets/spinner.module.css'

export function Spinner() {
  return (
    <div className={styles.wrapper}>
      <div className={styles.spinner}>
        {new Array(12).fill(0).map((_, index) => (
          <div key={index} className={styles.item} />
        ))}
      </div>
    </div>
  )
}
