import requester from './requester'

const requestPost = (url, data, silent = false) => {
  return requester.post(url, data, silent, 'acquiring')
}

const requestGet = (url, data, silent = false) => {
  return requester.get(url, data, silent, 'acquiring')
}
const requestPut = (url, data, silent = false) => {
  return requester.put(url, data, silent, 'acquiring')
}
const requestDelete = (url, data, silent = false) => {
  return requester.delete_(url, data, silent, 'acquiring')
}

const acquiringApi = {
  check: function (data = {}, silent = false) {
    return requestGet('manage/check', data, silent)
  },
  create: function (data = {}, silent = false) {
    return requestGet('manage/create', data, silent)
  },
  createCompany: function (data = {}, silent = false) {
    return requestPost('manage/company', data, silent)
  },
  changeCompanyInfo: function (data = {}, silent = false) {
    return requestPut('manage/company', data, silent)
  },
  removeCompany: function (data = {}, silent = false) {
    return requestDelete('manage/company', data, silent)
  },
  authStart: function (type, data) {
    return requestGet(`${type}/auth`, data)
  },
  authEnd: function (type, data) {
    return requestPost(`${type}/auth`, data)
  },
  getStatus: function (id) {
    return requestGet(`payment/status/${id}`, {}, true)
  },
}

export default acquiringApi
