import {
  applySnapshot,
  getSnapshot,
  Instance,
  SnapshotOut,
  types,
} from 'mobx-state-tree'
import { editableModel } from '../editable'

export const AgentGendersModel = types.model({
  id: types.maybeNull(types.number),
  name: types.maybeNull(types.string),
})

export const AgentReportStatusModel = types.model({
  job_id: types.maybeNull(types.number),
  message: types.maybeNull(types.string),
  status: types.maybeNull(types.number),
  progress: types.maybeNull(types.string),
})

export const AgentServiceContactData = types.model({
  company_activity: types.maybe(types.string),
  company_address: types.maybe(types.string),
  country: types.maybe(types.string),
  country_keywords: types.maybe(types.string),
  email: types.maybe(types.string),
  facebook: types.maybe(types.string),
  instagram: types.maybe(types.string),
  latitude: types.maybe(types.string),
  logitude: types.maybe(types.string),
  service_info: types.maybe(types.string),
  support_phone_num: types.maybe(types.string),
  support_phone_num_beeline: types.maybe(types.string),
  support_phone_num_megacom: types.maybe(types.string),
  support_phone_num_nurtelecom: types.maybe(types.string),
  support_phone_num_second: types.maybe(types.string),
  support_telegram_num: types.maybe(types.string),
  support_whatsapp_num: types.maybe(types.string),
  twitter: types.maybe(types.string),
  web_site: types.maybe(types.string),
  work_time: types.maybe(types.string),
})

export const AgentServiceExtOptions = types.model({
  coin_support: types.maybeNull(types.boolean),
  hide_requisite: types.maybeNull(types.boolean),
  offline: types.maybeNull(types.boolean),
  search_words: types.maybeNull(types.string),
  use_enrollment: types.maybeNull(types.boolean),
  use_identification: types.maybeNull(types.boolean),
})

export const AgentServiceData = types.model({
  contact_data: AgentServiceContactData,
  ext_options: AgentServiceExtOptions,
  fields: types.frozen([]),
  form_scheme: types.maybeNull(types.string),
  internal_revoke: types.boolean,
  keyboard_type: types.string,
  logo: types.string,
  requisite_message: types.string,
})

export const AgentAllServicesModel = types.model({
  blocked: types.maybe(types.boolean),
  category_name: types.string,
  categoryid: types.maybe(types.union(types.number, types.string)),
  category_id: types.maybe(types.union(types.number, types.string)),
  commission: types.maybeNull(types.string),
  data: types.maybe(types.union(AgentServiceData, types.frozen())),
  error: types.maybeNull(types.string),
  id: types.number,
  image: types.string,
  image_logo: types.string,
  info: types.maybeNull(types.string),
  is_checkable: types.maybeNull(types.boolean),
  is_down: types.boolean,
  is_revocable: types.boolean,
  keyboard_type: types.string,
  max_amount: types.maybeNull(types.number),
  max_length: types.maybeNull(types.number),
  min_amount: types.maybeNull(types.number),
  name: types.string,
  phone_number: types.maybeNull(types.string),
  placeholder: types.maybeNull(types.string),
  precision: types.maybeNull(types.number),
  provider: types.maybeNull(types.string),
  require_identification: types.boolean,
  service_id: types.maybe(types.number),
  tags: types.maybeNull(types.string),
  template: types.maybeNull(types.string),
  type: types.string,
  validation: types.maybeNull(types.string),
  web_address: types.maybeNull(types.string),
})

export const AgentFavoritesModel = types.model({
  amount: types.maybeNull(types.number),
  ap_id: types.number,
  auto_payment: types.boolean,
  contact_name: types.string,
  contact_record_id: types.number,
  data: types.frozen(),
  image: types.string,
  image_logo: types.maybeNull(types.string),
  next_auto_payment: types.string,
  requisite: types.string,
  service: types.string,
  service_id: types.number,
  type: types.string,
  user_id: types.number,
})

export const AgentFavoriteModel = types.model({
  name: types.string,
  requisite: types.string,
  options: types.map(types.frozen()),
  service_id: types.number,
})

export const AgentCategoryModel = types.model({
  blocked: types.boolean,
  categoryid: types.number,
  country: types.maybeNull(types.string),
  group_parent_id: types.number,
  id: types.number,
  image: types.string,
  image_logo: types.string,
  name: types.string,
  name_eng: types.maybeNull(types.string),
  name_kgs: types.maybeNull(types.string),
  parent: types.number,
  services: types.number,
  sorter_id: types.number,
  type: types.string,
})

export const AgentLoadResultModel = types.model({
  message: types.string,
  type: types.string,
})

export const AgentLastPaymentsModel = types.model({
  amount: types.number,
  batch_status_id: types.number,
  can_repay: types.maybe(types.boolean),
  can_cancel: types.maybe(types.boolean),
  cancel_date: types.maybeNull(types.string),
  cancel_user: types.maybeNull(types.string),
  cheque_path: types.maybeNull(types.string),
  cancel_user_id: types.maybeNull(types.number),
  client: types.string,
  client_id: types.number,
  commission: types.number,
  currency_id: types.number,
  data: types.frozen(),
  datetime: types.string,
  guid: types.string,
  id: types.number,
  image: types.maybe(types.string),
  message: types.maybeNull(types.string),
  note: types.maybeNull(types.string),
  oper_type: types.number,
  operation: types.string,
  owner_id: types.number,
  provider: types.string,
  rate: types.number,
  requisite: types.string,
  service: types.string,
  service_id: types.maybeNull(types.number),
  service_name: types.maybe(types.string),
  source: types.string,
  status: types.string,
  status_id: types.number,
  sub_code: types.string,
  transaction: types.string,
  type: types.maybe(types.string),
  value_date: types.maybeNull(types.string),
  transfer_type: types.maybeNull(types.string),
  transfer_requisite: types.maybeNull(types.string),
})

export const AgentLoadPaymentsModel = types.model({
  requisite: types.string,
  amount: types.string,
})

export const AgentServicesCommissionsModel = types.model({
  commission: types.string,
  id: types.number,
  image_logo: types.string,
  name: types.string,
})

export const AgentHistoryModel = types.model({
  amount: types.number,
  balance: types.optional(types.number, 0),
  avatar: types.maybeNull(types.string),
  can_repay: types.maybeNull(types.boolean),
  can_cancel: types.maybeNull(types.boolean),
  cheque_path: types.maybeNull(types.string),
  client: types.maybeNull(types.string),
  commission: types.maybeNull(types.number),
  datetime: types.string,
  full_name: types.maybeNull(types.string),
  guid: types.maybeNull(types.string),
  image: types.maybeNull(types.string),
  note: types.maybeNull(types.string),
  payment_id: types.maybeNull(types.number),
  requisite: types.maybeNull(types.string),
  service_name: types.maybeNull(types.string),
  service: types.maybeNull(types.string),
  service_id: types.maybeNull(types.number),
  source: types.maybeNull(types.string),
  status: types.maybeNull(types.string),
  status_id: types.maybeNull(types.number),
  type: types.maybeNull(types.string),
})

export const AgentHistoryOperationTypeModel = types.model({
  id: types.union(types.string, types.number),
  name: types.string,
  view: types.string,
})

const WorkingHoursModel = types.model({
  id: types.string,
  name: types.string,
})

const ShopTypesModel = types.model({
  id: types.string,
  name: types.string,
})

const ShopRegionCountryModel = types.model({
  id: types.string,
  name: types.string,
})

const ShopRegionModel = types.model({
  id: types.maybeNull(types.string),
  name: types.string,
  country: types.maybeNull(ShopRegionCountryModel),
  code: types.maybeNull(types.string),
})

const ShopCountryDataModel = types.model({
  country: types.string,
  sign: types.string,
})

const ShopCountryCurrencyModel = types.model({
  data: ShopCountryDataModel,
  id: types.string,
  name: types.string,
})

const ShopCountryModel = types.model({
  currency: types.maybe(ShopCountryCurrencyModel),
  id: types.maybe(types.string),
  name: types.maybe(types.string),
  code: types.maybe(types.string),
})

const ShopDistrictModel = types.model({
  id: types.string,
  name: types.string,
})

const AddressInfoModel = types.compose(
  types.model({
    shop_address: types.maybeNull(types.string),
    shop_city: types.maybeNull(types.string),
    shop_country: types.maybeNull(types.union(ShopCountryModel, types.string)),
    shop_home: types.maybeNull(types.string),
    shop_latitude: types.maybeNull(types.string),
    shop_longitude: types.maybeNull(types.string),
    shop_name: types.maybeNull(types.string),
    shop_phone: types.maybeNull(types.string),
    shop_district: types.maybeNull(
      types.union(ShopDistrictModel, types.string),
    ),
    shop_region: types.maybeNull(types.union(ShopRegionModel, types.string)),
    shop_type: types.maybeNull(ShopTypesModel),
    working_hours: types.maybeNull(WorkingHoursModel),
  }),
  editableModel(),
)

const AgentInfoModel = types.compose(
  types.model({
    agent_city: types.maybeNull(types.string),
    agent_country: types.maybeNull(types.union(ShopCountryModel, types.string)),
    agent_region: types.maybeNull(types.union(ShopRegionModel, types.string)),
    agent_district: types.maybeNull(
      types.union(ShopDistrictModel, types.string),
    ),
    house_number: types.maybeNull(types.string),
    street_name: types.maybeNull(types.string),
    flat_number: types.maybeNull(types.string),
  }),
  editableModel(),
)

const SubAgentModelData = types.compose(
  types.model({
    address_info: types.optional(AddressInfoModel, () =>
      AddressInfoModel.create(),
    ),
    agent_info: types.optional(AgentInfoModel, () => AgentInfoModel.create()),
    inn: types.maybeNull(types.string),
  }),
  editableModel(),
)

const AgentTemplatesData = types.compose(
  types.model({
    Fri: false,
    Mon: false,
    Sat: false,
    Sun: false,
    Thu: false,
    Tue: false,
    Wed: false,
    allow_duplication: false,
    end_time: '',
    start_time: '',
  }),
  editableModel(),
)

const CommissionModel = types.model({
  is_disabled: types.boolean,
  commission: types.union(types.string, types.number),
  max_amount: types.maybeNull(types.union(types.string, types.number)),
})

export const AgentTemplates = types.compose(
  types
    .model({
      commission: types.maybeNull(
        types.union(types.string, types.map(CommissionModel)),
      ),
      data: types.optional(AgentTemplatesData, {}),
      id: types.maybeNull(types.number),
      template: types.maybeNull(types.string),
      user_id: types.maybeNull(types.number),
      showDaySettings: false,
    })
    .actions((self) => {
      return {
        setCommission(id, value) {
          if (self.commission && typeof self.commission !== 'string') {
            self.commission.set(id, value)
          }
        },
        deleteCommission(id) {
          if (self.commission && typeof self.commission !== 'string') {
            self.commission.delete(id)
          }
        },
        setCommissionByName(id, name, value) {
          if (self.commission && typeof self.commission !== 'string') {
            if (!self.commission.get(id)) {
              self.commission.set(id, {
                is_disabled: false,
                commission: 0,
                max_amount: 0,
              })
            }
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            self.commission.get(id)[name] = value
          }
        },
      }
    }),
  editableModel(),
)

const BalanceModel = types.model({
  amount: types.maybeNull(types.number),
  code: types.maybeNull(types.string),
  display: types.maybeNull(types.string),
  id: types.maybeNull(types.number),
})
const SettingsModel = types.model({
  language: types.maybeNull(types.string),
})

export const SubAgentModel = types.compose(
  types
    .model({
      FIO: types.maybeNull(types.string),
      activation_code: types.maybeNull(types.string),
      address: types.maybeNull(types.string),
      avatar: types.maybeNull(types.string),
      balance: types.maybeNull(
        types.union(types.array(BalanceModel), types.number),
      ),
      birthday: types.maybeNull(types.string),
      blocking_reason: types.maybeNull(types.string),
      city: types.maybeNull(types.string),
      client_id: types.maybeNull(types.number),
      country: types.maybeNull(types.string),
      data: types.optional(SubAgentModelData, () => SubAgentModelData.create()),
      email: types.maybeNull(types.string),
      first_name: types.maybeNull(types.string),
      id: types.maybeNull(types.number),
      ip_address: types.maybeNull(types.string),
      is_activated: types.maybeNull(types.boolean),
      is_blocked: types.maybeNull(types.boolean),
      is_closed: types.maybeNull(types.boolean),
      is_identified: types.maybeNull(types.boolean),
      last_name: types.maybeNull(types.string),
      limit: types.maybeNull(types.number),
      login: types.maybeNull(types.string),
      nickname: types.maybeNull(types.string),
      notifications: types.maybeNull(types.string),
      passport: types.maybeNull(types.string),
      passport_expire: types.maybeNull(types.string),
      passport_issue: types.maybeNull(types.string),
      passport_issuer: types.maybeNull(types.string),
      patronymic: types.maybeNull(types.string),
      phonenumber: types.maybeNull(types.string),
      sexObj: types.maybeNull(AgentGendersModel),
      register_date: types.maybeNull(types.string),
      schedule_info: types.maybeNull(types.string),
      settings: types.maybeNull(types.union(SettingsModel, types.string)),
      sex: types.maybeNull(types.number),
      sub_code: types.maybeNull(types.union(types.string, types.number)),
      password: types.maybeNull(types.string),
      template_id: types.maybeNull(types.number),
      trial: types.maybeNull(types.number),
      used: types.maybeNull(types.number),
      user_type: types.maybeNull(types.number),
      sum: types.maybeNull(types.union(types.string, types.number)),
      comment: types.maybeNull(types.string),
      template: types.maybeNull(AgentTemplates),
    })
    .actions((self) => {
      return {
        setSexObj(value) {
          self.sexObj = { ...value }
        },
        setTemplate(value) {
          self.template = getSnapshot(value)
        },
        resetSubAgent() {
          applySnapshot(self, {})
        },
      }
    }),
  editableModel(),
)

export const AgentProfileModel = types.compose(
  types.model({
    sub_code: types.maybeNull(types.string),
    phonenumber: types.maybeNull(types.string),
    ip_address: types.maybeNull(types.string),
    last_name: types.maybeNull(types.string),
    first_name: types.maybeNull(types.string),
    patronymic: types.maybeNull(types.string),
    birthday: types.maybeNull(types.string),
    inn: types.maybeNull(types.string),
    passport: types.maybeNull(types.string),
    passport_issuer: types.maybeNull(types.string),
    passport_issue: types.maybeNull(types.string),
    address_info: types.optional(AddressInfoModel, () =>
      AddressInfoModel.create(),
    ),
  }),
  editableModel(),
)

export const AgentAktTypes = types.model({
  id: types.string,
  name: types.string,
})

export const AgentListTypeModel = types.model({
  id: types.string,
  name: types.string,
  stat: 'total',
})

export const SubAgentHistoryModel = types.model({
  amount: types.maybeNull(types.number),
  batch_status_id: types.maybeNull(types.number),
  can_repay: types.maybeNull(types.boolean),
  can_cancel: types.maybeNull(types.boolean),
  cancel_date: types.maybeNull(types.string),
  cancel_user_id: types.maybeNull(types.number),
  client: types.maybeNull(types.string),
  client_id: types.maybeNull(types.number),
  commission: types.maybeNull(types.number),
  currency_id: types.maybeNull(types.number),
  data: types.maybeNull(types.frozen({})),
  datetime: types.maybeNull(types.string),
  guid: types.maybeNull(types.string),
  id: types.maybeNull(types.number),
  image: types.maybeNull(types.string),
  message: types.maybeNull(types.string),
  note: types.maybeNull(types.string),
  oper_type: types.maybeNull(types.number),
  operation: types.maybeNull(types.string),
  owner_id: types.maybeNull(types.number),
  provider: types.maybeNull(types.string),
  rate: types.maybeNull(types.number),
  requisite: types.maybeNull(types.string),
  service: types.maybeNull(types.string),
  service_id: types.maybeNull(types.number),
  service_name: types.maybeNull(types.string),
  source: types.maybeNull(types.string),
  status: types.maybeNull(types.string),
  status_id: types.maybeNull(types.number),
  sub_code: types.maybeNull(types.string),
  transaction: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
  value_date: types.maybeNull(types.string),
})

export interface IAgentAllServicesModel
  extends Instance<typeof AgentAllServicesModel> {}

export interface IAgentLastPaymentsModel
  extends Instance<typeof AgentLastPaymentsModel> {}

export interface IAgentCategoryModel
  extends Instance<typeof AgentCategoryModel> {}

export interface AgentProfileModelSnapshotOut
  extends SnapshotOut<typeof AgentProfileModel> {}
