import * as FileSaver from 'file-saver'
import * as XLSX from 'xlsx'

export function exportToCSV(data_out, fileName) {
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
  const fileExtension = '.xlsx'
  const ws = XLSX.utils.json_to_sheet(data_out)
  const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
  const data = new Blob([excelBuffer], { type: fileType })
  FileSaver.saveAs(data, fileName + fileExtension)
}

export function downloadExcel(blob, fileName) {
  FileSaver.saveAs(blob, fileName)
}
