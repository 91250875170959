import {
  applySnapshot,
  flow,
  getType,
  onSnapshot,
  types,
} from 'mobx-state-tree'
import LocalStorage from './LocalStorage'

export function persistableModel(key: string, whitelist: string[]) {
  const actions = (self) => {
    let snapshotDispose

    return {
      afterCreate() {
        self.restorePersisted()
      },
      beforeDestroy() {
        snapshotDispose && snapshotDispose()
      },
      restorePersisted: flow(function* restorePersisted() {
        try {
          const snapshot = yield LocalStorage.get(key)
          if (snapshot) {
            if (getType(self).is(snapshot)) {
              applySnapshot(self, snapshot)
            }
          }
        } finally {
          snapshotDispose = onSnapshot(self, (snapshot: any) => {
            const saved = {}
            for (const k of whitelist) {
              saved[k] = snapshot[k]
            }
            LocalStorage.set(key, saved)
          })
        }
      }),
    }
  }
  return types.model().actions(actions)
}
