import React, { useCallback, useEffect, useReducer } from 'react'
import { Dimensions } from 'react-native'

const computeDimensions = ({ width, height }) => ({
  portrait: width <= height,
  landscape: width > height,
  xs: true,
  sm: width >= 576,
  md: width >= 768,
  lg: width >= 992,
  xl: width >= 1200,
  screenWidth: Math.floor(width),
  screenHeight: Math.floor(height),
})

const ACTION_UPDATE_DIMENSIONS = 'ACTION_UPDATE_DIMENSIONS'

const dimensionsReducer = (state, action) => {
  if (action.type === ACTION_UPDATE_DIMENSIONS) {
    const payload = computeDimensions(action.payload)
    const changed = Object.keys(payload).some((bp) => state[bp] !== payload[bp])
    if (changed) return { ...state, ...payload }
  }
  return state
}

export function useResponsive() {
  const [state, dispatch] = useReducer(
    dimensionsReducer,
    Dimensions.get('window'),
    computeDimensions,
  )

  const updateDimensions = useCallback(() => {
    dispatch({
      type: ACTION_UPDATE_DIMENSIONS,
      payload: Dimensions.get('window'),
    })
  }, [])

  useEffect(() => {
    const listener = Dimensions.addEventListener('change', updateDimensions)
    return () => listener?.remove()
  }, [useCallback])

  return state
}

export function withResponsive<P, S>(
  WrappedComponent: React.ComponentClass<P, S>,
): React.FunctionComponent<P> {
  return (props: P) => {
    const { sm } = useResponsive()
    return <WrappedComponent {...props} sm={sm} />
  }
}
