import { Instance, SnapshotIn, types } from 'mobx-state-tree'
import { editableModel } from '../editable'

const BillValidator = types.model({
  bills_reject: types.optional(types.string, ''),
  bv_model: types.optional(types.string, ''),
  bv_serial_number: types.optional(types.string, ''),
  bv_status: types.optional(types.string, ''),
  bv_text: types.optional(types.string, ''),
})

const CoinValidator = types.model({
  coins_reject: types.optional(types.string, ''),
  cv_model: types.optional(types.string, ''),
  cv_serial_number: types.optional(types.string, ''),
  cv_status: types.optional(types.string, ''),
  cv_text: types.optional(types.string, ''),
})

const Schedule = types
  .model({
    around_the_clock: types.optional(types.boolean, false),
    fri: types.optional(types.boolean, false),
    mon: types.optional(types.boolean, false),
    sat: types.optional(types.boolean, false),
    sun: types.optional(types.boolean, false),
    thu: types.optional(types.boolean, false),
    tue: types.optional(types.boolean, false),
    wed: types.optional(types.boolean, false),
    weekend: types.array(types.number),
    work_hours_end: types.optional(types.string, ''),
    work_hours_start: types.optional(types.string, ''),
  })
  .actions((self) => ({
    setData(name, value) {
      self[name] = value
    },
  }))

export const CommonInfo = types
  .model({
    address: types.optional(types.string, ''),
    blocked: types.optional(types.boolean, false),
    city: types.optional(types.string, ''),
    daily_limit: types.optional(types.number, 0),
    group_id: types.optional(types.number, 0),
    latitude: types.optional(types.number, 0),
    longitude: types.optional(types.number, 0),
    maintenance: types.optional(types.string, ''),
    reboot: types.optional(types.boolean, false),
    region: types.optional(types.string, ''),
    region_id: types.optional(types.number, 0),
    schedule: types.optional(Schedule, () => Schedule.create()),
    terminal_group: types.optional(types.string, ''),
    terminal_number: types.optional(types.string, ''),
    terminal_point_name: types.optional(types.string, ''),
    terminal_point_phone: types.optional(types.string, ''),
    terminal_status: types.optional(types.string, ''),
    terminal_type: types.optional(types.string, ''),
    type_id: types.optional(types.number, 0),
    work_time: types.optional(types.string, ''),
  })
  .actions((self) => ({
    setData(name, value) {
      self[name] = value
    },
  }))

const Connection = types.model({
  connection_lost_past: types.optional(types.string, ''),
  connection_lost_time: types.optional(types.string, ''),
  connection_status: types.optional(types.string, ''),
  connection_text: types.optional(types.string, ''),
  modem_name: types.optional(types.string, ''),
  modem_operator_name: types.optional(types.string, ''),
  modem_sim_number: types.optional(types.string, ''),
  signal_level: types.optional(types.string, ''),
  signal_stability: types.optional(types.string, ''),
  connection_month_error_count: types.optional(types.number, 0),
})

const DeviceInfo = types.model({
  hdd_info: types.optional(types.string, ''),
  hdd_state: types.optional(types.string, ''),
  motherboard_info: types.optional(types.string, ''),
  processor_info: types.optional(types.string, ''),
  processor_temperature: types.optional(types.string, ''),
  ram_info: types.optional(types.string, ''),
  touch_screen_name: types.optional(types.string, ''),
  processor_status: types.optional(types.string, ''),
})

const Payments = types.model({
  last_payment_past: types.optional(types.string, ''),
  last_payment_time: types.optional(types.string, ''),
  payment_status: types.optional(types.string, ''),
  payments_today: types.optional(types.number, 0),
  encashment_deposit: types.optional(types.number, 0),
  encashment_deposit_count: types.optional(types.number, 0),
})

const Printer = types.compose(
  types
    .model({
      paper_length: types.optional(types.string, ''),
      printer_model: types.optional(types.string, ''),
      printer_status: types.optional(types.string, ''),
      printer_text: types.optional(types.string, ''),
      printer_month_error_count: types.optional(types.number, 0),
      receipt_control: types.optional(types.string, 'receipt_auto'),
    })
    .actions((self) => ({
      setPaperLength(value) {
        self.paper_length = value
      },
    })),
  editableModel(),
)

const Rent = types
  .model({
    rent_account: types.optional(types.string, ''),
    rent_amount: types.optional(types.number, 0),
    rent_date: types.maybeNull(types.string),
    rent_group: types.optional(types.string, ''),
    rent_service: types.maybeNull(types.optional(types.string, '')),
  })
  .actions((self) => ({
    setData(name, value) {
      self[name] = value
    },
  }))

const Faults = types.compose(
  types.model({
    day_checked: types.maybeNull(types.boolean),
    enable_day: types.maybeNull(types.boolean),
    enable_night: types.maybeNull(types.boolean),
    night_checked: types.maybeNull(types.boolean),
  }),
  editableModel(),
)

const JournalItem = types.model({
  category: types.string,
  message: types.string,
  time: types.string,
})

export const Terminal = types.model({
  bill_validator: types.optional(BillValidator, () => BillValidator.create()),
  coin_validator: types.optional(CoinValidator, () => CoinValidator.create()),
  common_info: types.optional(CommonInfo, () => CommonInfo.create()),
  connection: types.optional(Connection, () => Connection.create()),
  connection_past: types.optional(types.string, ''),
  devices_info: types.optional(DeviceInfo, () => DeviceInfo.create()),
  journal: types.optional(types.array(JournalItem), []),
  payments: types.optional(Payments, () => Payments.create()),
  printer: types.optional(Printer, () => Printer.create()),
  rent: types.optional(Rent, () => Rent.create()),
  faults: types.optional(Faults, () => Faults.create()),
})

export interface ITerminal extends Instance<typeof Terminal> {}

export interface ITerminalSnapshotIn extends SnapshotIn<typeof Terminal> {}
