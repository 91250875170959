import { Linking } from 'react-native'
import {
  pay24 as pay24Api,
  agent as agentApi,
  wallet as walletApi,
} from '../api'
import { MESSAGE_TYPE } from '../constants'
import { isMobile } from '../utils'
import accountStore from './AccountStore'
import RootStore from './RootStore'
import ServicesStore from './ServiceStore'
import { v4 as uuidv4 } from 'uuid'
import round from 'lodash/round'
import { applySnapshot, getSnapshot, Instance, types } from 'mobx-state-tree'

export const FORM = {
  INITIAL: 1,
  CHECK: 2,
  PAYMENT_3D: 3,
  PAID: 4,
  FAIL: 5,
}

export const PaymentData = types
  .model({
    requisite: types.optional(types.string, ''),
    country: types.maybe(types.string),
    guid: types.maybeNull(types.string),
    form_scheme: types.maybeNull(types.string),
    amount: types.optional(types.maybeNull(types.number), null),
    payment_id: types.optional(types.number, 0),
    service_id: types.optional(types.number, 0),
    service_code: types.optional(types.string, ''),
    service_name: types.optional(types.string, ''),
    comment: types.optional(types.string, ''),
    theme: types.optional(types.frozen(), ''),
    errorMessage: types.optional(types.string, ''),
    customer_name: types.optional(types.string, ''),
    fixed_amount: types.optional(types.number, 0),
    totalAmount: types.optional(types.number, 0),
    paymentCommission: types.optional(types.number, 0),
    acquiringCommission: types.optional(types.string, ''),
    commission: types.optional(types.number, 0),
    step: 1,
    options: types.optional(types.map(types.frozen()), {}),
    document_id: types.optional(types.number, 0),
    payload: types.optional(types.maybeNull(types.frozen()), null),
    template: types.maybeNull(types.string),
  })
  .actions((self) => {
    let initialState = {}
    return {
      afterCreate: () => {
        initialState = getSnapshot(self)
      },
      reset: () => {
        applySnapshot(self, initialState)
      },
      setValue(key, value) {
        self[key] = value
      },
      set(value) {
        applySnapshot(self, value)
      },
      setOptions(key: string, value) {
        self.options.set(key, value)
      },
      deleteOptions(key) {
        self.options.delete(key)
      },
      resetOptions() {
        self.options.clear()
      },
    }
  })
  .views((self) => {
    return {
      getOptions(key: string) {
        return self.options.get(key)
      },
    }
  })

const actions = (_self) => {
  const self = _self as IPaymentStore
  return {
    afterCreate() {
      self.setAgent(false)
    },
    resetPayData() {
      self.paymentState = FORM.INITIAL
      self.step = 1
      self.warning = null
      pay24Api.cancelPaymentPoll()
    },
    setPaymentState(state = FORM.INITIAL) {
      self.paymentState = state
    },
    setPaymentWarning(warning: string | null) {
      self.warning = warning
    },
    setStep(step = 1) {
      self.step = step
    },
    setAgent(isAgent) {
      self.isAgent = isAgent
    },

    setButtonBlock(value) {
      self.buttonBlock = value
    },

    async searchServices(search) {
      const response = await self.getServiceApi().getServicesSearch(search)
      self.setServices(
        response.services.filter((s) => !s.is_down && !s.blocked),
      )
      return self.services
    },
    setOption(key, value) {
      if (!self.payData.options) {
        self.payData.reset()
      }

      if (key === 'gns_oblast_code') {
        self.deleteOption('gns_raion_code')
        self.deleteOption('gns_raion_text')

        self.deleteOption('gns_aimak_code')
        self.deleteOption('gns_aimak_text')
      }

      if (key === 'gns_raion_code') {
        self.deleteOption('gns_aimak_code')
        self.deleteOption('gns_aimak_text')
      }
      self.payData.setOptions(key, value)
    },
    deleteOption(key) {
      if (self.payData.options) {
        self.payData.deleteOptions(key)
      }
    },
    onPayAgain(amount: string | number, requisite: string) {
      self.payData.set({
        ...self.payData,
        amount: typeof amount === 'string' ? parseFloat(amount) : amount,
        requisite: requisite,
        service_id: 100000,
      })
    },
    autoPassportFill() {
      let user = RootStore.user
      let fio = `${user?.data?.profile?.last_name} ${user?.data?.profile?.first_name}`
      if (user?.data?.profile?.patronymic) {
        fio.concat(' ' + user?.data?.profile?.patronymic)
      }
      self.setOption('fio', fio)
      self.setOption('payer_dateto', user?.data?.profile?.passport_issue)
      self.setOption('payer_issuer', user?.data?.profile?.passport_issuer)
      self.setOption('payer_passport_num', user?.data?.profile?.passport)
      if (user?.data?.profile?.inn) {
        self.setOption('payer_inn', user?.data?.profile?.inn)
      } else {
        self.setOption('payer_inn', '00000000000000')
      }
    },
    async requisiteCheck(source) {
      const { payData } = self
      self.setButtonBlock(true)
      const params = { ...payData }
      const { setValue, resetOptions } = payData
      if (!params.options) {
        resetOptions()
      }
      // setOptions('explanation', payData.comment)
      try {
        const r = await self.getPaymentApi().paymentCheck({
          source,
          params,
          prod_gateway: true,
        })
        setValue(
          'customer_name',
          r.customer_name ? r.customer_name.trim() : 'найден',
        )
        if (r.extra && r.extra.amount_to_pay) {
          setValue('amount', parseFloat(r.extra.amount_to_pay))
        }
        if (r.params && r.params.amount_to_pay) {
          setValue('amount', parseFloat(r.params.amount_to_pay))
        }
        setValue('paymentCommission', r.commission)

        if (source) {
          setValue(
            'totalAmount',
            self.calc_total(payData.amount, r.commission, source.commission),
          )
          setValue(
            'acquiringCommission',
            source.commission ? source.commission : '0',
          )
        } else {
          setValue(
            'totalAmount',
            self.calc_total(payData.amount, r.commission, 0),
          )
          setValue('acquiringCommission', '0')
        }
        let amount = payData.amount ? payData.amount : 0
        setValue(
          'commission',
          source?.commission ? round(payData.totalAmount - amount, 2) : 0.0,
        )
        self.setButtonBlock(false)
        return r
      } catch (e) {
        self.setButtonBlock(false)
        return Promise.reject(e)
      }
    },
    async pay(source, callback) {
      self.setButtonBlock(true)
      if (self.payData.getOptions('gns_oblast_code')) {
        self.setOption('gns_aimak_code', '')
        self.setOption('gns_aimak_text', '')
      }
      try {
        self.payData.guid = uuidv4()
        if (callback) {
          callback()
        }
        if (!self.isAgent) {
          self.payData.setValue(
            'payload',
            await pay24Api.paymentStart({
              source: source,
              ...self.payData,
            }),
          )
          if (isMobile && source.id === 'mbank') {
            await Linking.openURL(self.payData.payload?.url)
          }
          self.setPaymentState(FORM.PAYMENT_3D)
          self.setButtonBlock(false)
        }
        try {
          let resp
          if (self.isAgent) {
            resp =
              self.payData.service_id === 100000
                ? await agentApi.internalTransfer({
                    customer_id: self.payData.requisite,
                    ...self.payData,
                  })
                : await agentApi.pay(self.payData)
          } else {
            resp = await pay24Api.paymentPoll(self.payData.payload.operation_id)
          }
          if (resp.status === 'fail') {
            RootStore.setMessage({
              type: MESSAGE_TYPE.WARNING,
              text: resp.message,
            })
            self.payData.setValue('errorMessage', resp.message)
            self.setPaymentState(FORM.FAIL)
            self.setStep(self.step + 1)
          } else {
            if (resp.status !== 'pending') {
              self.payData.setValue(
                'payment_id',
                resp.operation_id || resp.payment_id,
              )
              self.setPaymentState(FORM.PAID)
              self.setStep(self.step + 1)
              accountStore.refreshDebit()
            }
          }
          self.setButtonBlock(false)
          return resp
        } catch (e: any) {
          RootStore.setMessage({ type: MESSAGE_TYPE.WARNING, text: e.message })
          this.setPaymentState(FORM.FAIL)
          self.setButtonBlock(false)
        }
      } catch (e) {
        self.setButtonBlock(false)
        return Promise.reject(e)
      }
    },
    setEmasPayInfo(value) {
      self.emasPayInfo = value
    },
    getEmasPayInfo() {
      pay24Api.getUniversityBill().then((r) => {
        self.setEmasPayInfo(r)
      })
    },
  }
}

const views = (_self) => {
  const self = _self as IPaymentStore
  return {
    getServiceApi() {
      return self.isAgent ? agentApi : walletApi
    },
    getPaymentApi() {
      return self.isAgent ? agentApi : pay24Api
    },
    calc_total(amount, payment_commission, acquiring_commission) {
      let calc_commission = (t, c) => {
        let commission = 0
        if (c) {
          if (isNaN(c) && c.indexOf('%') !== -1) {
            commission = (t * parseFloat(c.trim('%'))) / 100
          } else {
            commission = parseFloat(c)
          }
        }
        return commission
      }
      let total = parseFloat(amount || 0)
      total += calc_commission(total, payment_commission)
      total += calc_commission(total, acquiring_commission)
      return round(total, 2)
    },
  }
}

const properties = {
  buttonBlock: false,
  payData: types.optional(PaymentData, {}),
  emasPayInfo: types.frozen(),
  paymentState: FORM.INITIAL,
  step: FORM.INITIAL,
  warning: types.maybeNull(types.string),
}

export const PaymentStoreModel = types
  .compose(types.model(properties), ServicesStore)
  .actions(actions)
  .views(views)
  .named('PaymentStore')

interface IPaymentStore extends Instance<typeof PaymentStoreModel> {}

const PaymentStore = PaymentStoreModel.create()
export default PaymentStore
