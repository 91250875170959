import { useRef } from 'react'
import { useReactToPrint } from 'react-to-print'

export function usePrintRef() {
  const viewRef = useRef(null)
  const print = useReactToPrint({
    content: () => viewRef.current || null,
  })
  return { viewRef, print }
}
