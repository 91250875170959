import colors from './colors'

export const IDENTIFICATION_PENDING = 1
export const IDENTIFICATION_APPROVED = 2
export const IDENTIFICATION_REJECTED = 3

export const MESSAGE_TYPE = {
  SUCCESS: 'success',
  INFO: 'info',
  WARNING: 'warning',
  ERROR: 'error',
}

export const MESSAGE_COLOR = {
  [MESSAGE_TYPE.SUCCESS]: colors.darkGreen,
  [MESSAGE_TYPE.INFO]: colors.blue,
  [MESSAGE_TYPE.WARNING]: colors.yellow,
  [MESSAGE_TYPE.ERROR]: colors.red,
}

export const MESSAGE_ICONS = {
  [MESSAGE_TYPE.SUCCESS]: 'alert-circle-outline',
  [MESSAGE_TYPE.INFO]: 'checkbox-marked-circle-outline',
  [MESSAGE_TYPE.WARNING]: 'alert-circle',
  [MESSAGE_TYPE.ERROR]: 'alert-circle-outline',
}
export default {
  APP_NAME: 'Pay24',
  DATE_FORMAT: 'DD.MM.YYYY',
  DB_DATE_FORMAT: 'YYYY-MM-DD',
  DATE_TIME_FORMAT: 'DD.MM.YY (HH:mm)',
  DATE_TIME_FORMAT_WITH_SECONDS: 'DD.MM.YY (LTS)',
  CATEGORY_TRANSFER: -10,
  CATEGORY_FAVORITES: -9,
  TAB_HEIGHT: 60,
  SERVICE_WALLET: 100000,
  SERVICE_OVERDRAFT: -110000,
  GOOGLE_MAPS_API_KEY: 'AIzaSyA0pxTPn4Q01lVJgdhbqKej1tnPebLqvAc',
  MAPBOX_API_KEY:
    'pk.eyJ1Ijoiam9tYXJ0IiwiYSI6ImNsZ3hpY2MxcDAwdHEza3AyZzIwOG5reXgifQ.EhNQL5wtgkFA0yyb3OQbyw',
  RECAPTCHA_SITE_KEY: '6LepkMUUAAAAAJtZ6x36V4oMq1T83W4SyivKZskm',
  RECAPTCHA_SECRET_KEY: '6LepkMUUAAAAAPj9F7eDfnBi7wZrhEFeZPRZr_Ok',
  _2GIS_KEY: '5233e2c2-85b7-4ff1-8b30-8ccbdd1d1106',
  DEFAULT_LANGUAGE_INDEX: 5, // RU
  PHONE_CODES: {
    kg: {
      country: 'Кыргызстан',
      flag: 'kg.png',
      mask: '(999) 99 99 99',
      placeholder: '(___) __ __ __',
      code: '+996',
    },
    kz: {
      country: 'Казахстан',
      flag: 'kz.png',
      mask: '(999) 999 99 99',
      placeholder: '(___) ___ __ __',
      code: '+7',
    },
    uz: {
      country: 'Узбекистан',
      flag: 'uz.png',
      mask: '(99) 999 99 99',
      placeholder: '(__) ___ __ __',
      code: '+998',
    },
    tj: {
      country: 'Таджикистан',
      flag: 'tj.png',
      mask: '(999) 999 99 99',
      placeholder: '(___) ___ __ __',
      code: '+992',
    },
    tr: {
      country: 'Турменистан',
      flag: 'tr.png',
      mask: '(999) 999 99 99',
      placeholder: '(___) ___ __ __',
      code: '+90',
    },
    ru: {
      country: 'Россия',
      flag: 'ru.png',
      mask: '(999) 999 99 99',
      placeholder: '(___) ___ __ __',
      code: '+7',
    },
    us: {
      country: 'США',
      flag: 'en.png',
      mask: '(999) 999 99 99',
      placeholder: '(___) ___ __ __',
      code: '+1',
    },
    int: {
      country: 'Другие страны',
      flag: 'other_country.png',
      mask: '999999999999999999999999999',
      code: '+',
      placeholder: '___________',
    },
  },
  PHONE_COUNTRIES: [
    {
      title: 'kyrgyz',
      country: 'kyrgyzstan',
      flag: 'kg.png',
      code: 'kg',
      lang: 'кыр',
    },
    {
      title: 'kazak',
      country: 'kazakhstan',
      flag: 'kz.png',
      code: 'kz',
      lang: 'qaz',
    },
    {
      title: 'uzbek',
      country: 'uzbekistan',
      flag: 'uz.png',
      code: 'uz',
      lang: 'Ozb',
    },
    {
      title: 'tajik',
      country: 'tajikistan',
      flag: 'tj.png',
      code: 'tj',
      lang: 'тоҷ',
    },
    {
      title: 'turkish',
      country: 'turkey',
      flag: 'tr.png',
      code: 'tr',
      lang: 'tur',
    },
    {
      title: 'russian',
      country: 'russia',
      flag: 'ru.png',
      code: 'ru',
      lang: 'рус',
    },
    {
      title: 'english',
      country: 'usa',
      flag: 'en.png',
      code: 'us',
      lang: 'eng',
    },
    {
      title: 'other_countries',
      country: 'other_countries',
      flag: 'other_country.png',
      code: 'int',
    },
  ],
  LANGUAGES: [
    {
      title: 'kyrgyz',
      country: 'kyrgyzstan',
      flag: 'kg.png',
      code: 'kg',
      lang: 'кыр',
      name: 'Кыргызча',
    },
    {
      title: 'kazak',
      country: 'kazakhstan',
      flag: 'kz.png',
      code: 'kz',
      lang: 'Қаз',
      name: 'Қазақ тілі',
    },
    // {
    //   title: 'uzbek',
    //   country: 'uzbekistan',
    //   flag: 'uz.png',
    //   code: 'uz',
    //   lang: 'Ozb',
    // },
    // {
    //   title: 'tajik',
    //   country: 'tajikistan',
    //   flag: 'tj.png',
    //   code: 'tj',
    //   lang: 'тоҷ',
    // },
    // {
    //   title: 'turkish',
    //   country: 'turkey',
    //   flag: 'tr.png',
    //   code: 'tr',
    //   lang: 'tur',
    // },
    {
      title: 'russian',
      country: 'russia',
      flag: 'ru.png',
      code: 'ru',
      lang: 'рус',
      name: 'Русский',
    },
    {
      title: 'english',
      country: 'usa',
      flag: 'en.png',
      code: 'en',
      lang: 'eng',
      name: 'English',
    },
  ],

  pay24IosUrl: 'https://apps.apple.com/kg/app/pay24/id1536527592',
  pay24androidUrl:
    'https://play.google.com/store/apps/details?id=com.pay24.app',

  chargeIosUrl: 'https://apps.apple.com/kg/app/pay24/id6466851250',
  chargeAndroidUrl:
    'https://play.google.com/store/apps/details?id=com.pay24.charge24',

  evionIosUrl: 'https://apps.apple.com/kg/app/evion/id6478523618',

  IDENTIFICATION_STATUSES: {
    [IDENTIFICATION_PENDING]: {
      en: 'Pending',
      ru: 'В ожидании',
      kg: 'Күтүүдө',
    },
    [IDENTIFICATION_APPROVED]: {
      en: 'Identified',
      ru: 'Идентифицирован',
      kg: 'Идентификацияланган',
    },
    [IDENTIFICATION_REJECTED]: {
      en: 'Rejected',
      ru: 'Отклонен',
      kg: 'Четке кагылды',
    },
  },
  COORDINATES: {
    lat: 42.85612319090842,
    lng: 74.63255200000003,
  },
  tariffTypes: [
    { id: 1, name: 'time', key: 'duration' },
    { id: 2, name: 'кВт', key: 'power' },
  ],
}

export const MONEY_CASH_ACCOUNT_PREFIX = '111'
export const INTERNAL_TRANSFER_WALLET_ID = 999999
export const INTERNAL_TRANSFER_AGENT_ID = 100000

export const CHARGE24_SERVICE_ID = 3481
