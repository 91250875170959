import {
  autorun,
  configure,
  makeAutoObservable,
  observable,
  observe,
} from 'mobx'
import { getSnapshot } from 'mobx-state-tree'
import * as api from '../api'
import colors from '../colors'
import i18n from '../translate/i18n'
import { isMobile, Keychain } from '../utils'
import LocalStorage from './LocalStorage'
import { IUser, User, UserSnapshotIn } from './models/User'

configure({
  enforceActions: 'never',
})

const cnt = {
  givenName: '',
  familyName: '',
  middleName: '',
  phoneNumbers: [],
  emailAddresses: [],
}

export interface IToken {
  access_token: string
}
class RootStore {
  user: IUser | null = null

  busyCount = 0

  auth: IToken | null = null

  providers = observable<any>([])

  language = ''

  message: any = null

  contact = cnt

  appReady = false

  pinExists = false

  testMode = false

  fullScreen = false

  constructor() {
    makeAutoObservable(this)

    observe(
      this,
      'user',
      () => {
        // if (this.user) {
        //   chatStore.login()
        // }
        this.buildProviders()
      },
      true,
    )
  }

  buildProviders() {
    let pp: Array<any> = []
    if (this.user && this.user.data.finance) {
      for (let i = 0; i < this.user.data.finance.length; i++) {
        if (this.user.data.finance[i].type === 'provider') {
          pp.push({
            id: this.user.data.finance[i].id,
            title: this.user.data.finance[i].name,
            img: 'logo-Atlanta.png',
            icon: 'format-list-numbered',
            bg: colors.red,
            path: 'edit',
          })
        }
      }
    }
    this.providers.replace(pp)
  }

  get isBusy() {
    return this.busyCount > 0
  }

  get isAuthorized() {
    return !!this.token && !!this.user
  }

  setBusy(state) {
    let count = this.busyCount
    if (!state) {
      count = count > 0 ? count - 1 : 0
    } else {
      count = count + 1
    }
    this.busyCount = count
  }

  setMessage(msg) {
    this.message = msg
  }

  setFullScreen(val: boolean) {
    this.fullScreen = val
  }

  get token() {
    return this.auth?.access_token
  }

  async loadApp() {
    this.appReady = false
    let user = await LocalStorage.get('user')
    this.auth = await LocalStorage.get('token')
    if (user && user.user_id) {
      await this.setUserInfo(user)
      // if (this.user?.data?.agent || this.user?.data?.sub_agent) {
      //   await agentStore.getUserInfo()
      // }
    } else {
      try {
        await Keychain.resetPassword()
        await this.logout()
      } catch (e) {
        console.log('loadApp logout error', e)
      }
    }

    const language = (await LocalStorage.get('language')) || 'ru'
    this.setLanguage(language)
    this.appReady = true
  }

  async removeContacts() {
    this.user?.data.profile.social.contacts.clear()
    return this.saveProfile()
  }

  async saveProfile() {
    if (!this.user) {
      return
    }
    const userFromStorage = await LocalStorage.get('user')
    let user = await api.passport.saveProfile(
      getSnapshot(this.user.data.profile),
    )
    return this.setUserInfo({ ...user, user_id: userFromStorage.user_id })
  }

  cleanProfilePassportData() {
    const profile = this.user?.data.profile
    profile?.setPassportData('resident', null)
    profile?.setPassportData('documentNumber', null)
    profile?.setPassportData('dateOfIssue', null)
    profile?.setPassportData('validityDate', null)
    profile?.setPassportData('last_name', null)
    profile?.setPassportData('first_name', null)
    profile?.setPassportData('sex', null)
    profile?.setPassportData('birthday', null)
    profile?.setPassportData('passport_issuer', null)
    profile?.setPassportData('passport_issue', null)
    profile?.setPassportData('passport_expire', null)
  }

  async getUserInfo() {
    let user
    if (!this.user) {
      user = await LocalStorage.get('user')
    }
    if (!user) {
      user = await api.passport.getUserInfo({}, true)
      await this.setUserInfo({ ...user, user_id: this.user?.user_id })
    }
    return { ...user, user_id: this.user?.user_id }
  }

  async setUserInfo(user: UserSnapshotIn, auth?: IToken) {
    if (auth) {
      this.auth = auth
    }
    await LocalStorage.set('user', { ...this.user, ...user })
    this.user = User.create(user)
  }

  async logout() {
    await api.passport.deletePushToken()
    await LocalStorage.remove('user')
    await LocalStorage.remove('token')
    await LocalStorage.remove('push_token')
    if (isMobile) {
      await Keychain.resetPassword()
    }
    if (this.isAuthorized) {
      await api.passport.logout()
    }
    this.auth = null
    this.pinExists = false
    this.user = null

    // await api.agent.logout()
    // await walletApi.logout()
  }

  setLanguage(languageCode = 'ru') {
    if (this.user?.data.profile.settings) {
      this.user.data.profile.settings.changeSettings('language', languageCode)
    }
    this.language = languageCode
  }

  uploadPhoto(data) {
    api.passport.avatarUpload(data).then(() => this.getUserInfo())
  }

  uploadPassport(data) {
    return api.passport.passportUpload(data)
  }

  deletePhoto() {
    api.passport.avatarDelete().then(() => this.getUserInfo())
  }

  resetContact() {
    this.contact = cnt
  }

  setContact(contact) {
    this.contact = contact
  }

  deleteContact(contact) {
    api.passport.contactDelete(contact).then(() => this.getUserInfo())
  }

  toggleBlockContact(contact) {
    api.passport.contactToggleBlock(contact).then(() => this.getUserInfo())
  }

  hasAccess(pageId) {
    return this.user?.data?.pages?.some((p) => p.id === pageId)
  }
}

const rootStore = new RootStore()

autorun(() => {
  i18n.changeLanguage(rootStore.language)
  if (rootStore.language) {
    LocalStorage.set('language', rootStore.language)
  }
})

export default rootStore
