import requester from './requester'

const requestPost = (url, data, silent = false) => {
  return requester.post(url, data, silent, 'passport')
}

const requestPut = (url, data, silent = false) => {
  return requester.put(url, data, silent, 'passport')
}

const requestGet = (url, data, silent = false) => {
  return requester.get(url, data, silent, 'passport')
}

const requestDelete = (url, data, silent = false) => {
  return requester.delete_(url, data, silent, 'passport')
}

const adminApi = {
  check: function (page_id, action) {
    return requestPost('admin/check', { page_id, action }, true)
  },
  verify: function (code) {
    return requestPost('admin/verify', { code }, true)
  },
  getUsers: function (data = {}) {
    return requestPost('admin/users', data, true)
  },
  getUser: function (data = {}) {
    return requestPost('admin/get_user', data)
  },
  getRoles: function (data = {}, silent = false) {
    return requestPost('admin/roles', data, silent)
  },
  getPages: function () {
    return requestPost('admin/pages', {}, true)
  },
  getRolePages: function (role_id) {
    return requestPost('admin/roles/pages', { role_id }, true)
  },
  savePage: function (page) {
    let p = { ...page }
    return requestPost('admin/page/save', { page: p }, true)
  },
  saveUserRole: function (user_id, role_id) {
    let data = {
      user_id: user_id,
      role_id: role_id,
    }
    return requestPost('admin/users/role_save', data, true)
  },
  savePageRole: function (role_id, page_id, access) {
    let data = {
      role_id: role_id,
      page_id: page_id,
      access: access,
    }
    return requestPost('admin/roles/page_save', data, true)
  },
  getIdentification: function (data) {
    return requestGet('admin/identification', data)
  },
  getIdentificationUser: function (id) {
    return requestGet('admin/identification', { id })
  },
  getIdentificationHistory: function (data = {}) {
    return requestPost('admin/identification/history', data)
  },
  getIdentificationReasons: function () {
    return requestGet('admin/identification/reasons', { lang: 'ru' })
  },
  postIdentificationStatus: function (data) {
    return requestPut('admin/identification', data)
  },
  confirmIdentificationStatus: function (data) {
    return requestPost('admin/identification/confirm', data)
  },
  deleteIdentificationVideoCall: function (id) {
    return requestDelete('admin/identification/video_approve', {
      id,
    })
  },
  addRole: function (data) {
    return requestPost('admin/roles/add', data)
  },
  deleteRole: function (id) {
    return requestDelete('admin/roles/delete', { id })
  },
}

export default adminApi
