import React, { useState } from 'react'
import { useTheme as useKittenTheme } from '@ui-kitten/components'
import { ThemeContext } from '../theme/ThemeContext'
import { isEvion } from '../utils'
import { colors } from '../index'

export * from './useDragScroll'
export * from './useResponsive'
export * from './useSelectLocation'
export * from './useDebounce'
export * from './useEffectOnce'
export * from './useUpdateEffect'
export * from './useIsomorphicLayoutEffect'
export * from './useIsFirstRender'
export * from './useHover'
export * from './useEventListener'
export * from './useKeyPress'
export * from './useIsKeyboardShown'
export * from './useGeolocation'
export * from './useGeolocationCountry'
export * from './useDrawerPages'
export * from './usePrintRef'
export * from './useCopyToClipboard'
export * from './recaptcha'
export * from './useIncDecDate'
export * from './useRequester'
export * from './useQrCode'
export * from './useAppState'

export function useInput(initialValue?) {
  const [value, setValue] = useState(initialValue || '')

  const onChangeText = (text) => setValue(text)
  const clear = () => setValue('')

  return { value, onChangeText, clear }
}

export function useTheme() {
  const kittenTheme = useKittenTheme()
  const { theme, toggleTheme } = React.useContext(ThemeContext)
  const isLight = theme === 'light'
  const variables = {
    ...kittenTheme,
    ...{
      primary: isEvion
        ? colors.evion
        : isLight
        ? colors.primary
        : colors.orange,
    },
  }

  return { toggleTheme, ...variables, isLight }
}
