import requester from '../api/requester'

const terminalApi = {
  monitoring: function (data = {}, silent = false) {
    return requester.post('monitoring', data, silent, 'terminal')
  },
  monitoringItem: function (id, silent = false) {
    return requester.post('monitoring_item', { id }, silent, 'terminal')
  },
  monitoringItemFaults: function (data, silent = false) {
    return requester.post('monitoring_faults_edit', data, silent, 'terminal')
  },
  monitoringResetBill: function (terminal_id, silent = false) {
    return requester.post(
      'monitoring_reset',
      { terminal_id, device: 'bill_validator' },
      silent,
      'terminal',
    )
  },
  monitoringResetCoin: function (terminal_id, silent = false) {
    return requester.post(
      'monitoring_reset',
      { terminal_id, device: 'coin_validator' },
      silent,
      'terminal',
    )
  },
  terminalEdit: function (data = {}, silent = false) {
    return requester.post('terminal_edit', data, silent, 'terminal')
  },
  monitoringReboot: function (terminal_id, silent = false) {
    return requester.post(
      'monitoring_reboot',
      { terminal_id },
      silent,
      'terminal',
    )
  },
  reportDetailed: function (data = {}, silent = false) {
    return requester.post('reports/detailed', data, silent, 'terminal')
  },
  reportGroup: function (data = {}, silent = false) {
    return requester.post('reports/grouping', data, silent, 'terminal')
  },
  encashmentDeposit: function (data = {}, silent = false) {
    return requester.post('encashment/deposit', data, silent, 'terminal')
  },
  toEncashmentDeposit: function (data = {}, silent = false) {
    return requester.post(
      '/encashment/manual_encashment',
      data,
      silent,
      'terminal',
    )
  },
  encashmentWithdraw: function (data = {}, silent = false) {
    return requester.post('encashment/withdraw', data, silent, 'terminal')
  },

  reportBalances: function (data = {}, silent = true) {
    return requester.post('balances', data, silent, 'terminal')
  },
  reportBalancesHistory: function (data = {}, silent = false) {
    return requester.post('balances/history', data, silent, 'terminal')
  },

  userPermission: function (data = {}, silent = false) {
    return requester.post('user/permissions', data, silent, 'terminal')
  },
  userReadMessage: function (data = {}, silent = false) {
    return requester.post(
      '/user/accept_broadcast_notification',
      data,
      silent,
      'terminal',
    )
  },
  userInfo: function (data = {}, silent = false) {
    return requester.post('user/info', data, silent, 'terminal')
  },
  userEdit: function (data = {}, silent = false) {
    return requester.post('user/edit', data, silent, 'terminal')
  },
  userNotificationEdit: function (data = {}, silent = false) {
    return requester.post(
      'user/balance_notification_edit',
      data,
      silent,
      'terminal',
    )
  },
  userSubscribe: function (data = {}, silent = false) {
    return requester.post(
      'user/subscribe_to_telegram',
      data,
      silent,
      'terminal',
    )
  },
  getMonitoringFilter: function (data = {}, silent = false) {
    return requester.post('monitoring_filter', data, silent, 'terminal')
  },
  getDetailedFilter: function (data = {}, silent = false) {
    return requester.post('reports/detailed_filter', data, silent, 'terminal')
  },
  getGroupingFilter: function (data = {}, silent = false) {
    return requester.post('reports/grouping_filter', data, silent, 'terminal')
  },
  getDepositFilter: function (data = {}, silent = false) {
    return requester.post('encashment/deposit_filter', data, silent, 'terminal')
  },
  getWithdrawFilter: function (data = {}, silent = false) {
    return requester.post(
      'encashment/withdraw_filter',
      data,
      silent,
      'terminal',
    )
  },
  getWithdrawTotal: function (data = {}, silent = false) {
    return requester.post('encashment/withdraw_total', data, silent, 'terminal')
  },
  getHistoryFilter: function (data = {}, silent = false) {
    return requester.post('balances/history_filter', data, silent, 'terminal')
  },
  getTransferFilter: function (data = {}, silent = false) {
    return requester.post('balances/transfers_filter', data, silent, 'terminal')
  },
  transferCheck: function (terminal_number, silent = false) {
    return requester.post(
      'balances/transfer_check',
      { terminal_number },
      silent,
      'terminal',
    )
  },
  transferSubmit: function (data = {}, silent = false) {
    return requester.post('balances/transfer_submit', data, silent, 'terminal')
  },
  getQuickPanel: function (data = {}, silent = false) {
    return requester.post('quick_panel', data, silent, 'terminal')
  },
  getTechnicalKpd: function (data = {}, silent = false) {
    return requester.post('reports/fault_terminals', data, silent, 'terminal')
  },
  getDevicesHistoryFilter: function (data = {}, silent = false) {
    return requester.post(
      'reports/devices_history_filter',
      data,
      silent,
      'terminal',
    )
  },
  getDevicesHistory: function (data = {}, silent = false) {
    return requester.post('reports/devices_history', data, silent, 'terminal')
  },
  getTerminalCoordinates: function (data = {}, silent = false) {
    return requester.post('terminal_coordinates', data, silent, 'terminal')
  },
  getTerminalInfo: function (data = {}, silent = false) {
    return requester.post('user/info', data, silent, 'terminal')
  },
  getUserEdit: function (data = {}, silent = false) {
    return requester.post('user/edit', data, silent, 'terminal')
  },
  getGroupInfo: function (group_id = null, silent = false) {
    return requester.post('/user/group_item', { group_id }, silent, 'terminal')
  },
  getLastTransfers: function (data = {}, silent = false) {
    return requester.post('/balances/last_transfers', data, silent, 'terminal')
  },
}

export default terminalApi
