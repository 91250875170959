import { flow, Instance, types } from 'mobx-state-tree'
import { charge24, passport } from '../api'
import pay24Api from '../api/pay24'
import { MESSAGE_TYPE } from '../constants'
import { i18n } from '../translate'
import { isCharge24 } from '../utils'
import RootStore from './RootStore'

export const STEP_REQUEST_OTP = 1
export const STEP_INPUT_DATA = 2
export const STEP_SEND_DATA = 3

const actions = (_self) => {
  const self = _self as ISecurityStore
  return {
    saveNewPassword() {
      return passport.changePassword(
        self.password,
        self.newPassword,
        self.confirmNewPassword,
      )
    },
    setPassword(value) {
      self.password = value
    },
    setNewPassword(value) {
      self.newPassword = value
    },
    setConfirmPassword(value) {
      self.confirmNewPassword = value
    },
    setPhone(value) {
      self.phone = value
    },
    setPhoneOldCode(value) {
      self.phoneOldCode = value
    },
    setNewPhone(value) {
      self.newPhone = value
    },
    resetPhoneState() {
      self.phoneState = STEP_REQUEST_OTP
    },
    setPhoneNewCode(value) {
      self.phoneNewCode = value
    },
    setPhoneStep() {
      self.phoneState--
    },
    setEmail(value) {
      self.email = value
    },
    setEmailOldCode(value) {
      self.emailOldCode = value
    },
    setNewEmail(value) {
      self.newEmail = value
    },
    setEmailNewCode(value) {
      self.emailNewCode = value
    },
    setVisible(value: boolean) {
      self.visible = value
    },
    setShowAvatar(value: boolean) {
      self.showAvatar = value
    },
    setPhoneCode(value) {
      self.phoneCode = value
    },
    setStep(val) {
      self.phoneState = val
    },
    phoneChange1: flow(function* () {
      try {
        let resp = yield passport.loginChangeStep1()
        self.phoneState = STEP_INPUT_DATA
        RootStore.setMessage({
          type: MESSAGE_TYPE.INFO,
          text: 'На номер телефона отправлен код подтверждения.',
        })
        self.reset()
        return resp
      } catch (e) {
        return {}
      }
    }),
    phoneChange2: flow(function* (): any {
      try {
        let resp = yield passport.loginChangeStep2(
          self.phoneOldCode,
          self.phoneCode + self.newPhone,
        )
        self.phoneState = STEP_SEND_DATA
        RootStore.setMessage({
          type: MESSAGE_TYPE.INFO,
          text: 'На номер телефона отправлен код подтверждения.',
        })
        return resp
      } catch (e) {
        return {}
      }
    }),
    phoneChange3: flow(function* (): any {
      try {
        let resp = yield passport.loginChangeStep3(
          self.phoneNewCode,
          self.newPhone,
        )
        if (isCharge24) {
          yield charge24.updatePhoneNumber()
        } else {
          yield pay24Api.updatePhoneNumber()
        }
        self.phoneState = STEP_REQUEST_OTP
        yield RootStore.getUserInfo()
        return resp
      } catch (e) {
        return {}
      }
    }),
    emailChange1: flow(function* () {
      try {
        let resp = yield passport.emailChangeStep1()
        self.emailState = STEP_INPUT_DATA
        RootStore.setMessage({
          type: MESSAGE_TYPE.INFO,
          text: 'На почту отправлен код подтверждения.',
        })
        return resp
      } catch (e) {
        return {}
      }
    }),
    emailChange2: flow(function* (): any {
      try {
        let resp = yield passport.emailChangeStep2(
          self.emailOldCode,
          self.newEmail,
        )
        self.emailState = STEP_SEND_DATA
        RootStore.setMessage({
          type: MESSAGE_TYPE.INFO,
          text: 'На почту отправлен код подтверждения.',
        })
        return resp
      } catch (e) {
        return {}
      }
    }),
    emailChange3: flow(function* (): any {
      try {
        let resp = yield passport.emailChangeStep3(
          self.emailNewCode,
          self.newEmail,
        )
        self.emailState = STEP_REQUEST_OTP
        yield RootStore.getUserInfo()
        return resp
      } catch (e) {
        return {}
      }
    }),

    reset() {
      self.setPhoneCode('')
      self.setNewPhone('')
      self.setPhoneOldCode('')
      self.setPhoneNewCode('')
    },
  }
}

const views = (_self) => {
  const self = _self as ISecurityStore
  return {
    get validPassword() {
      return self.password.length < 7 ? i18n.t('min_7_symbols') : ' '
    },
    get validNewPassword() {
      return self.newPassword.length < 7 ? i18n.t('min_7_symbols') : ' '
    },
    get validConfirmNewPassword() {
      return self.confirmNewPassword.length < 7 ? i18n.t('min_7_symbols') : ' '
    },
    get disabled() {
      return (
        self.password.length < 7 ||
        self.newPassword.length < 7 ||
        self.confirmNewPassword.length < 7
      )
    },
    get incorrectNewPassword() {
      return self.newPassword !== self.confirmNewPassword
    },
    get visibleCheck() {
      return self.visible
    },
    get showAvatarCheck() {
      return self.showAvatar
    },
  }
}

export const SecurityStoreModel = types
  .model({
    phone: '',
    email: types.maybeNull(types.string),
  })
  .volatile(() => ({
    visible: false,
    showAvatar: false,
    password: '',
    newPassword: '',
    confirmNewPassword: '',
    newPhone: '',
    phoneCode: types.optional(types.string, '+996'),
    phoneOldCode: '',
    phoneNewCode: '',
    newEmail: '',
    emailOldCode: '',
    emailNewCode: '',
    phoneState: STEP_REQUEST_OTP,
    emailState: STEP_REQUEST_OTP,
  }))
  .actions(actions)
  .views(views)
  .named('SecurityStore')

export interface ISecurityStore extends Instance<typeof SecurityStoreModel> {}

const SecurityStore = SecurityStoreModel.create()
export { SecurityStore }
