import { types } from 'mobx-state-tree'

export const RoleModel = types.model({
  id: types.maybe(types.string),
  name: types.maybe(types.string),
})

export const Pay24System = types.model({
  id: types.maybe(types.string),
  name: types.maybe(types.string),
})

export const PageModel = types.model('PageModel', {
  id: types.string,
  name: types.string,
  path: types.maybeNull(types.string),
  parent_id: types.maybeNull(types.string),
  system: types.optional(Pay24System, {}),
  is_group: types.boolean,
  icon: types.string,
  sort_order: types.number,
  pages: types.optional(types.array(types.late(() => PageModel)), []),
  is_new: false,
})
