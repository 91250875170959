import { Instance, SnapshotIn, types } from 'mobx-state-tree'

export const Account = types.model({
  commission: types.maybe(types.string),
  db_id: types.maybe(types.string),
  id: types.string,
  img: types.maybe(types.string),
  name: types.maybe(types.string),
  platform: types.optional(types.string, 'acquiring'),
  source: types.maybe(types.string),
  requisite: types.frozen(),
  url: types.maybe(types.string),
  balance: types.maybeNull(types.number),
  groups: types.frozen(),
  group: types.frozen(),
  currencies: types.frozen(),
  company: types.maybeNull(types.string),
})

export const AccountServiceModel = types.model({
  blocked: types.frozen(),
  category_name: types.string,
  categoryid: types.number,
  commission: types.maybeNull(types.string),
  data: types.frozen(),
  error: types.maybeNull(types.string),
  id: types.number,
  image: types.string,
  image_logo: types.string,
  info: types.frozen(),
  is_checkable: types.boolean,
  is_down: types.boolean,
  is_revocable: types.boolean,
  keyboard_type: types.string,
  max_amount: types.maybeNull(types.number),
  max_length: types.maybeNull(types.number),
  min_amount: types.maybeNull(types.number),
  name: types.string,
  phone_number: types.maybeNull(types.string),
  placeholder: types.string,
  precision: types.maybeNull(types.number),
  provider: types.string,
  require_identification: types.boolean,
  service_id: types.number,
  tags: types.maybeNull(types.string),
  template: types.maybeNull(types.string),
  type: types.string,
  validation: types.string,
  web_address: types.maybeNull(types.string),
})

export const AccountSelectedTypeModel = types.model({
  color: types.string,
  icon: types.string,
  id: types.maybeNull(types.number),
  name: types.string,
})

export interface IAccount extends Instance<typeof Account> {}

export interface IAccountSelectedType
  extends Instance<typeof AccountSelectedTypeModel> {}

export interface IAccountSnapshotIn extends SnapshotIn<typeof Account> {}
