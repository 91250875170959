import React, { ComponentType, lazy, Suspense } from 'react'
import { ErrorBoundary } from './error_boundary'
import { Spinner } from './spinner'

export const withLazy = <C extends ComponentType<any>>(
  lazyComponent: () => Promise<{ default: C }>,
) => {
  const Component = lazy(lazyComponent)
  const LazyComponent = (
    props: C extends ComponentType<infer P> ? P : never,
  ) => {
    return (
      <ErrorBoundary>
        <Suspense fallback={<Spinner />}>
          <Component {...props} />
        </Suspense>
      </ErrorBoundary>
    )
  }
  LazyComponent.displayName = `lazy(${Component.name || 'Component'})`
  return LazyComponent
}
