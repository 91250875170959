import { flow, types } from 'mobx-state-tree'
import { admin, passport } from '../api'
import { MESSAGE_TYPE } from '../constants'
import { i18n } from '../translate'
import { PageModel, Pay24System, RoleModel } from './models/AdminModel'
import RootStore from './RootStore'

export const EditPageModel = types.model('EditPageModel', {
  id: types.string,
  name: types.string,
  path: types.maybeNull(types.string),
  parent_id: types.maybeNull(types.string),
  system: types.optional(Pay24System, {}),
  is_group: types.boolean,
  icon: types.string,
  sort_order: types.number,
  is_new: true,
})

export const RolePageModel = types.model('RolePageModel', {
  role_id: types.string,
  page_id: types.string,
  read: types.boolean,
  write: types.boolean,
  delete: types.boolean,
})

const newPage = {
  id: 'NEW',
  name: i18n.t('new_page'),
  path: '',
  parent_id: null,
  system: { id: 'Pay24', name: 'Pay24' },
  is_group: false,
  icon: 'folder-plus',
  sort_order: 0,
}

const UserModel = types.model('UserModel', {
  id: types.number,
  username: types.string,
  name: types.maybeNull(types.string),
  roles: types.optional(types.array(RoleModel), []),
  blocked: types.boolean,
})

const userHistory = types.model('UserHistory', {
  date: types.string,
  blocked: types.maybeNull(types.boolean),
  reason: types.maybeNull(types.string),
  who_block: types.union(types.string, types.number),
})

const actions = (_self) => {
  const self = _self // as ISystemStore
  return {
    clear() {
      self.roles.replace([])
    },
    getUsers: flow(function* () {
      let query = {
        search: self.search_text,
        page_size: self.pageSize,
        page_number: self.pageNumber,
      }
      let r = yield admin.getUsers(query)
      self.users = r.data.users
      self.totalItems = r.data.total
      self.pageSize = r.data.page_size
      self.pageNumber = r.data.page_number
    }),
    getUserHistories: async function (user_id) {
      const response = await passport.blockInfo(user_id)
      if (response.length === 0) {
        RootStore.setMessage({
          type: MESSAGE_TYPE.ERROR,
          text: 'Нет данных, история пуста!',
        })
      } else {
        self.setUserHistories(response)
      }
    },
    clearUsers: function () {
      self.users.replace([])
    },
    setUserId: function (user_id) {
      self.user_id = user_id
    },
    setPageSize: function (page) {
      self.pageSize = page
    },
    setPageNumber: function (number) {
      self.pageNumber = number
    },
    resetUserId: function () {
      self.user_id = 0
    },
    getRoles: flow(function* () {
      let r = yield admin.getRoles()
      self.roles.replace(r.roles)
    }),
    toggleRole: flow(function* (role) {
      const user = self.users?.find((u) => u.id === self.user_id)
      let r = yield admin.saveUserRole(user?.id, role.id)

      if (r.roles) {
        user.roles.replace(r.roles)
      }
    }),
    getPages: flow(function* () {
      let r = yield admin.getPages()
      self.pages.replace(r.pages)
    }),
    getRolePages: flow(function* (role_id) {
      let r = yield admin.getRolePages(role_id)
      self.rolePages.replace(r.pages)
    }),
    setPage: function (page) {
      if (page) {
        Object.keys(page).map((key) => {
          if (key !== 'pages') {
            self.page[key] = page[key]
          }
        })
      } else {
        Object.keys(newPage).map((key) => {
          self.page[key] = newPage[key]
        })
      }
    },
    setSystem: function (sys) {
      if (self.page) {
        self.page.system = sys
      }
    },
    savePage: flow(function* () {
      yield admin.savePage(self.page)
      yield self.getPages()
    }),
    setRoleId: function (role_id) {
      self.role_id = role_id
    },
    setUserName: function (name) {
      self.user_name = name
    },
    setReasonText(value: string) {
      self.reason_text = value
    },
    blockUser: async function (id, blocked) {
      await passport.blockUser(id, blocked, self.reason_text)
      await self.getUsers()
      self.setReasonText('')
    },
    resetRoleId: function () {
      self.role_id = ''
    },
    setUserHistories(history) {
      self.userHistories = history
    },
    togglePageInRole: flow(function* (page, access = undefined) {
      let rp = yield admin.savePageRole(SystemStore.role_id, page.id, access)
      if (rp.role_pages) {
        self.rolePages.replace(rp.role_pages)
      }
    }),
    setSearchText: function (val) {
      self.search_text = val
    },
    getSystemTypes: function () {
      return [
        { id: 'SYS_USER', name: 'users', path: 'users' },
        { id: 'SYS_ROLE', name: 'roles', path: 'roles' },
        { id: 'SYS_PAGE', name: 'pages', path: 'pages' },
        { id: 'PRM', name: 'system_params', path: 'params_payment' },
      ]
    },
  }
}

const views = (_self) => {
  const self = _self // as ISystemStore
  return {
    get isPageValid() {
      return (
        self.page &&
        self.page.id &&
        self.page.name &&
        // && ((self.page.is_group && !self.page.path) || (!self.page.is_group && self.page.path))
        self.page.system
      )
      // && self.page.icon
    },
    get selectedUser() {
      return self.users?.find((u) => u.id === self.user_id)
    },
    isInRole(role_id) {
      return self.users
        ?.find((u) => u.id === self.user_id)
        ?.roles?.some((rr) => rr.id === role_id)
    },
    get rolesOfSelectedUser() {
      return self.users?.find((u) => u.id === self.user_id)?.roles
    },
    get selectedRole() {
      return self.roles?.find((r) => r.id === self.role_id)
    },
    isPageInRole(page_id) {
      return self.rolePages?.some(
        (rp) => rp.role_id === self.role_id && rp.page_id === page_id,
      )
    },
    hasAccessInRole(page_id, access) {
      const role = self.rolePages?.find(
        (rp) => rp.role_id === self.role_id && rp.page_id === page_id,
      )
      if (role) {
        return role[access]
      }
      return false
    },
  }
}

const volatile = () => {
  return {
    totalItems: 0,
    pageSize: 50,
    pageNumber: 1,
  }
}

export const SystemStoreModel = types
  .model({
    page: types.map(EditPageModel),
    pages: types.optional(types.array(PageModel), []),
    roles: types.optional(types.array(RoleModel), []),
    role_id: types.optional(types.string, ''),
    user_id: types.optional(types.number, 0),
    user_name: types.optional(types.string, ''),
    users: types.optional(types.array(UserModel), []),
    userHistories: types.optional(types.array(userHistory), []),
    rolePages: types.optional(types.array(RolePageModel), []),
    search_text: types.optional(types.string, ''),
    reason_text: types.optional(types.string, ''),
  })
  .volatile(volatile)
  .actions(actions)
  .views(views)
  .named('SystemStore')

const SystemStore = SystemStoreModel.create()
export default SystemStore
