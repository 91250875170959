import React from 'react'
import copy from 'copy-to-clipboard'

type CopiedValue = string | boolean
type CopyFn = (text: any) => void

export function useCopyToClipboard(): [CopiedValue, CopyFn] {
  const [isCopied, setCopied] = React.useState(false)
  const handleCopy: CopyFn = (text) => {
    if (typeof text === 'string' || typeof text === 'number') {
      copy(text.toString())
      setCopied(true)
    } else {
      setCopied(false)
      console.error(
        `Cannot copy typeof ${typeof text} to clipboard, must be a string or number.`,
      )
    }
  }
  return [isCopied, handleCopy]
}
