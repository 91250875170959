import { pay24 as pay24Api, wallet as walletApi } from '../api'
import {
  amountFormatCurrency,
  dateFormatDB,
  dateTimeFormat,
  getStatusText,
  isMobile,
  PERIOD,
} from '@pay24/common/src/utils'
import { MESSAGE_TYPE } from '../constants'
import { AgentAllServicesModel, AgentCategoryModel } from './models/Agent'
import { editableModel } from './editable'
import LocalStorage from './LocalStorage'
import rootStore from './RootStore'
import { flow } from 'mobx'
import { Instance, types } from 'mobx-state-tree'
import moment from 'moment/moment'

export const STATUS = {
  PENDING: 'pending',
  PROCESSING: 'processing',
  FAIL: 'fail',
  HOLD: 'hold',
  SUCCESS: 'success',
}

export const STATUSES = {
  d: [
    { id: null, name: 'all' },
    { id: STATUS.PROCESSING, name: STATUS.PROCESSING },
    { id: STATUS.FAIL, name: STATUS.FAIL },
    { id: STATUS.HOLD, name: STATUS.HOLD },
    { id: STATUS.PENDING, name: STATUS.PENDING },
    { id: STATUS.SUCCESS, name: STATUS.SUCCESS },
  ],
  c: [
    { id: null, name: 'all' },
    { id: STATUS.PROCESSING, name: STATUS.PROCESSING },
    { id: STATUS.PENDING, name: STATUS.PENDING },
    { id: STATUS.HOLD, name: STATUS.HOLD },
    { id: STATUS.SUCCESS, name: STATUS.SUCCESS },
  ],
}

export const PAYMENTS_TYPE = [
  { name: 'payments', id: 'd' },
  { name: 'deposits', id: 'c' },
]

export const OPERATION = { EXPENSE: 'd', INCOME: 'c' }

let request

const actions = (_self) => {
  const self = _self as IPaymentHistoryStore
  return {
    setFinancialRecordsResponse(r, reset = false) {
      self.setValue(
        'history',
        self.tableView === 'grid' && !reset
          ? self.history.concat(r.records)
          : r.records,
      )
      self.setValue('count', r.count)
      self.setValue('sum', r.sum)
      self.setValue(
        'amount',
        r.records.reduce((a, c) => a + c.amount, 0),
      )
      self.setValue(
        'excelData',
        self.tableView === 'list'
          ? r.records.map((item) => {
              return {
                Дата: dateTimeFormat(item.rec_date),
                Услуга: item.data?.payload?.service_name,
                Реквизит: item.data?.payload?.requisite,
                Сумма: amountFormatCurrency(item.amount),
                Коммисия: amountFormatCurrency(item.data?.payload?.commission),
                'Метод оплаты': item.data?.payload?.source?.name,
                Статус: getStatusText(item.status),
              }
            })
          : self.excelData,
      )
      self.setLoading(false)
    },
    getHistory(reset = false, dc?: string) {
      self.setLoading(true)
      const data = {
        date_from: dateFormatDB(self.date_from),
        date_to: dateFormatDB(self.date_to),
        dc: dc ?? PAYMENTS_TYPE[0].id,
        status: self.status && self.status.id,
        service_id: self.service_id,
        requisite: self.requisite,
        page_number: self.page_number,
        items_per_page: self.page_size,
      }
      request = pay24Api.financialRecords(data)
      request.then((r) => self.setFinancialRecordsResponse(r, reset))
    },
    requestCancel() {
      request && request.cancel()
    },
    getCategories: flow(function* () {
      const local_categories = yield LocalStorage.get('categories')
      if (local_categories) {
        self.setValue('categories', local_categories.categories)
      } else {
        walletApi
          .getServices({ only_categories: true })
          .then((data) => {
            const _categories = data.categories.sort(
              (a, b) => a.sorter_id - b.sorter_id,
            )
            self.setValue('categories', _categories)
          })
          .catch((e) =>
            rootStore.setMessage({
              type: MESSAGE_TYPE.ERROR,
              text: e.message || e.toString(),
            }),
          )
      }
    }),
    getServices(category_id: number) {
      walletApi.getServicesBy({ category_id }).then(({ services }) => {
        const _services = services.filter((s) => !s.is_down && !s.blocked)
        self.setValue('services', _services)
      })
    },
    reset() {
      self.history = []
      self.excelData = []
      self.setValue('service_id', null)
    },
    setLoading(loading: boolean) {
      self.loading = loading
    },
    setDc(o) {
      self.dc = o
    },
    setDate(period: moment.unitOfTime.DurationConstructor = 'day') {
      if (period === 'day') {
        self.date_from = dateFormatDB(moment(self.date_to))
      } else {
        self.date_from = dateFormatDB(moment(self.date_to).subtract(1, period))
      }
      self.period = period
    },
    incDate() {
      self.date_from = dateFormatDB(moment(self.date_from).add(1, 'day'))
      self.date_to = dateFormatDB(moment(self.date_to).add(1, 'day'))
    },
    decDate() {
      self.date_from = dateFormatDB(moment(self.date_from).subtract(1, 'day'))
      self.date_to = dateFormatDB(moment(self.date_to).subtract(1, 'day'))
    },
    setView(view) {
      self.tableView = view
      self.history = []
      self.getHistory()
    },
    setPageNumber(pageNum: number) {
      self.page_number = pageNum
    },
    setPageSize(size: number) {
      self.page_size = size
    },
  }
}
const views = (_self) => {
  const self = _self as IPaymentHistoryStore
  return {
    get loadingCheck() {
      return self.loading
    },
    get dcCheck() {
      return self.dc.name === 'deposits'
    },
  }
}

export const PaymentHistoryStoreModel = types.compose(
  types
    .model({
      history: types.optional(types.frozen(), []),
      excelData: types.frozen(),
      status: types.maybeNull(types.frozen()),
      categories: types.array(AgentCategoryModel),
      services: types.array(AgentAllServicesModel),
      service_id: types.maybeNull(types.number),
      category_id: types.maybeNull(types.number),
      requisite: types.optional(types.string, ''),
      page_number: types.optional(types.number, 1),
      page_size: types.optional(types.number, 25),
    })
    .volatile(() => ({
      date_from: dateFormatDB(moment().subtract(1, 'days')),
      date_to: dateFormatDB(moment()),
      tableView: isMobile ? 'grid' : 'list',
      loading: false,
      period: PERIOD.DAY,
      amount: 0,
      count: 0,
      sum: 0,
      dc: PAYMENTS_TYPE[0],
    }))
    .actions(actions)
    .views(views)
    .named('PaymentHistoryStore'),
  editableModel(),
)

interface IPaymentHistoryStore
  extends Instance<typeof PaymentHistoryStoreModel> {}

const PaymentHistoryStore = PaymentHistoryStoreModel.create()
export default PaymentHistoryStore
