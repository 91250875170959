import React from 'react'
import { Button } from 'react-native'

const AppRestartButton = (props: { onRestart: () => Promise<void> }) => {
  const restart = async () => {
    await props.onRestart()
    window.location.reload()
  }
  return <Button onPress={restart} title={'Перезагрузить'} />
}

export default AppRestartButton
