import { useState } from 'react'

export interface PagesProperty<S extends string, P = undefined> {
  screen: S
  params?: P
}

export function useDrawerPages<S extends string, P = undefined>() {
  const [pages, setPages] = useState<PagesProperty<S, P>[]>([])

  const openPage = (page: PagesProperty<S, P>) => {
    setPages((prevState) => [...prevState, page])
  }

  const hasPage = (screen: S) => pages.some((page) => page.screen === screen)

  const onBackPress = () => {
    pages.pop()
    setPages([...pages])
  }

  const onClose = () => {
    setPages([])
  }

  return { pages, openPage, hasPage, onBackPress, onClose }
}
