import {
  applySnapshot,
  Instance,
  SnapshotIn,
  SnapshotOut,
  types,
} from 'mobx-state-tree'
import moment from 'moment'
import { PageModel, RoleModel } from './AdminModel'
import { editableModel } from '../editable'
import constants from '../../constants'

export const UserSocial = types
  .model({
    contacts: types.array(types.frozen()),
    instagram: types.maybeNull(types.string),
    facebook: types.maybeNull(types.string),
    ticktok: types.maybeNull(types.string),
    twitter: types.maybeNull(types.string),
    youtube: types.maybeNull(types.string),
    telegram: types.maybeNull(types.string),
    whatsapp: types.maybeNull(types.string),
  })
  .actions((self) => ({
    setSocial(name: string, val: any) {
      self[name] = val
    },
  }))

const TokenInfo = types.model({
  token: types.identifier,
  info: types.optional(types.frozen(), {}),
})
const Partner = types.model({
  id: types.number,
  type: '',
  name: '',
  provider_services: types.array(types.frozen()),
  group_id: types.maybeNull(types.frozen()),
})
const IdentificationVideo = types.model({
  date: types.maybeNull(types.string),
  messenger_type: types.maybeNull(types.string),
  lang: types.maybeNull(types.string),
  phone: types.maybeNull(types.string),
  time: types.maybeNull(types.string),
})
const IdentificationProperties = types.model({
  birthday_match: types.maybe(types.boolean),
  face_match: types.maybe(types.boolean),
  first_name_match: types.maybe(types.boolean),
  inn_match: types.maybe(types.boolean),
  last_name_match: types.maybe(types.boolean),
  patronymic_match: types.maybe(types.boolean),
  video_approve_info: types.maybe(IdentificationVideo),
  reject_reason: types.maybeNull(types.frozen()),
  video: types.maybeNull(types.string),
})

const resident = { id: types.number, name: types.string }
const residentCard = { id: types.number, name: types.string }

const settingProps = {
  notifications: types.frozen(),
  language: '',
}
const UserSettings = types.model(settingProps).actions((self) => ({
  changeSettings(name: TSetting, value) {
    self[name] = value
  },
}))
const userPassportProps = {
  country: types.maybeNull(types.frozen()),
  first_name: types.maybeNull(types.string),
  face_image: types.maybeNull(types.string),
  front_image: types.maybeNull(types.string),
  back_image: types.maybeNull(types.string),
  resident_document_image: types.maybeNull(types.string),
  last_name: types.maybeNull(types.string),
  patronymic: types.maybeNull(types.string),
  sex: types.maybeNull(types.union(types.number, types.string)),
  birthday: types.maybeNull(types.frozen()),
  birthplace: types.maybeNull(types.string),
  inn: types.maybeNull(types.string),
  passport: types.maybeNull(types.string),
  passport_issuer: types.maybeNull(types.string),
  passport_issue: types.maybeNull(types.frozen()),
  passport_expire: types.maybeNull(types.frozen()),
  resident: types.maybeNull(types.model(resident)),
  residentCard: types.maybeNull(types.model(residentCard)),
  documentNumber: types.maybeNull(types.string),
  dateOfIssue: types.maybeNull(types.string),
  validityDate: types.maybeNull(types.string),
  sanction: types.maybeNull(types.number),
}
const UserProfile = types
  .compose(
    types.model({
      ...userPassportProps,
      social: types.optional(UserSocial, () => UserSocial.create()),
      address_reg_info_text: types.maybeNull(types.string),
      document: types.maybeNull(types.frozen()),
      defaultApp: types.maybeNull(types.string),
      identification: types.maybeNull(IdentificationProperties),
      identification_status: types.optional(types.number, 0),
      email: types.maybeNull(types.string),
      avatar: types.maybeNull(types.string),
      nickname: types.maybeNull(types.string),
      showFio: types.optional(types.boolean, false),
      settings: types.optional(UserSettings, () => UserSettings.create()),
    }),
    editableModel(),
  )
  .actions((self) => {
    let initialState = {}
    return {
      setPassportData(name: TUserPassportData, value, date = false) {
        if (date) {
          self[name] = moment(value).format(constants.DB_DATE_FORMAT)
        } else {
          self[name] = value
        }
      },
      resetIdentificaition() {
        self.identification = null
      },
      reset: () => {
        applySnapshot(self, initialState)
      },
    }
  })
const Chat = types.model({ userId: '' })
const userDataProps = {
  profile: types.optional(UserProfile, () => UserProfile.create()),
  agent: types.maybeNull(Partner),
  sub_agent: types.maybeNull(Partner),
  terminal: types.maybeNull(Partner),
  email: types.maybeNull(types.string),
  emas: types.maybeNull(types.frozen()),
  wallet: types.maybeNull(Partner),
  finance: types.maybeNull(types.array(Partner)),
  rocket_chat: types.optional(Chat, () => Chat.create()),
  default_debit: types.maybeNull(types.string),
  default_credit: types.maybeNull(types.string),
  roles: types.optional(types.array(RoleModel), []),
  pages: types.optional(types.array(PageModel), []),
  register_date: types.maybeNull(types.string),
}
const UserData = types.model(userDataProps).actions((self) => ({
  setData(name: TUserData, value) {
    self[name] = value
  },
}))

export const User = types.model({
  data: types.optional(UserData, () => UserData.create()),
  login: types.optional(types.string, ''),
  name: types.optional(types.string, ''),
  id: types.maybeNull(types.number),
  identification_approve_date: types.maybeNull(types.string),
  identification_status: types.maybeNull(types.number),
  identification_submit_date: types.maybeNull(types.string),
  approver: types.maybeNull(types.string),
  confirmed: types.maybeNull(types.boolean),
  user_id: types.maybeNull(types.number),
  register_date: types.maybeNull(types.string),
})

export interface IUser extends Instance<typeof User> {}

export interface UserSnapshotIn extends SnapshotIn<typeof User> {}

export interface TokenInfoSnapshotIn extends SnapshotIn<typeof TokenInfo> {}

export type TSetting = keyof typeof settingProps

export type TUserData = keyof typeof userDataProps

export type TUserPassportData = keyof typeof userPassportProps

// export type TAddressData = keyof typeof addressProps

export interface UserSnapshotOut extends SnapshotOut<typeof User> {}
