import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import * as constants from '../constants'
import platform from '../platform.json'
import i18n from '../translate/i18n'
import error_codes from '../translate/error_codes.json'
import en from '../translate/errors/en.json'
import ru from '../translate/errors/ru.json'
import kg from '../translate/errors/kg.json'
import { isAndroid, isDevMode, isEvion, isIos, isMobile } from '../utils'
import { RootStore, LocalStorage, AdminStore } from '../store'

const error_list_lang = {
  en: en,
  ru: ru,
  kg: kg,
}

const AUTHORIZATION_FAILED = 64
const INVALID_TOKEN = 115
const BLOCK_GOVERNMENT = 520
const BLOCK_SYSTEM = 521
const UPDATE_APP_VERSION = 517

export const BASE_URL = platform.pay24.url

const client = isIos ? 5 : isAndroid ? 3 : 1

const header: AxiosRequestConfig = {
  timeout: 60000,
  responseType: 'json',
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Credentials': 'true',
    'Content-Type': 'application/json',
    merchant: isEvion ? 'evion' : '',
    crossorigin: true,
  },
}

const axiosInstance = axios.create(header)

interface CancellablePromise<T> extends Promise<T> {
  cancel: () => void
}

const onRequest = async (
  config: AxiosRequestConfig,
): Promise<AxiosRequestConfig> => {
  const { silent } = config.params
  const { url } = config
  let token = RootStore.token
  if (!token) {
    const auth = await LocalStorage.get('token')
    token = auth?.access_token
  }

  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }
  if (url?.includes('upload')) {
    config.headers['Content-Type'] = 'multipart/form-data'
  }

  if (!silent) RootStore.setBusy(true)

  return config
}

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
  console.error(`[request error] [${JSON.stringify(error)}]`)
  RootStore.setBusy(false)
  if (!error.config.params.silent) {
    RootStore.setMessage({
      type: constants.MESSAGE_TYPE.ERROR,
      text: error.message || 'Network Error',
    })
  }
  return Promise.reject(error)
}

const onResponse = (response: AxiosResponse): Promise<AxiosResponse> => {
  RootStore.setBusy(false)
  const { data } = response
  const { service, silent } = response.config.params

  if (response.config.responseType === 'blob') {
    return Promise.resolve(response)
  }

  if (service === 'chat') {
    if (data.success || (data.status && data.status === 'success')) {
      return Promise.resolve(data)
    } else {
      if (isDevMode()) {
        RootStore.setMessage({
          type: constants.MESSAGE_TYPE.ERROR,
          text: response.statusText,
        })
      } else {
        RootStore.setMessage({
          type: constants.MESSAGE_TYPE.ERROR,
          text: 'Chat Error',
        })
      }
      return Promise.reject(response.statusText)
    }
  }

  if (service === 'terminal') {
    if (data.success) {
      return Promise.resolve(data)
    }
    if (!silent) {
      RootStore.setMessage({
        type: constants.MESSAGE_TYPE.ERROR,
        text: data.comment,
      })
    }
    return Promise.reject(data)
  }

  if (typeof data.result === 'undefined' || data.result === 0) {
    return Promise.resolve(data)
  }

  if (!silent) {
    let error = error_list_lang[i18n.language]
      ? error_list_lang[i18n.language][error_codes[data.result]] || data.message
      : data.message

    RootStore.setMessage({
      type: constants.MESSAGE_TYPE.ERROR,
      text: error.toString().replace(/[^0-9-a-z-A-Z-А-Я-а-я-\s]+/g, ''),
      result: data.result,
    })
  }

  if (
    [
      AUTHORIZATION_FAILED,
      INVALID_TOKEN,
      BLOCK_SYSTEM,
      BLOCK_GOVERNMENT,
      UPDATE_APP_VERSION,
    ].includes(data.result)
  ) {
    RootStore.logout()
  }
  if (data.result === 412) {
    AdminStore.setAdminOTPRequired(true)
  }

  return Promise.reject(data)
}

const onResponseError = (error: AxiosError): Promise<AxiosError> => {
  RootStore.setBusy(false)
  console.error(`[response error]`, JSON.stringify(error))
  const silent = error.config?.params?.silent
  if (!axios.isCancel(error) && !silent) {
    RootStore.setMessage({
      type: constants.MESSAGE_TYPE.ERROR,
      text: i18n.t('internal_server_error'),
    })
  }
  return Promise.reject(error)
}

axiosInstance.interceptors.request.use(onRequest, onRequestError)
axiosInstance.interceptors.response.use(onResponse, onResponseError)

function get<T>(
  url,
  params = {},
  silent = false,
  service: null | string = null,
  baseUrl: null | string = null,
  config: AxiosRequestConfig | null = null,
) {
  const { token, cancel } = axios.CancelToken.source()
  const fullUrl = createUrl(baseUrl, service, url)
  const request: Partial<CancellablePromise<T>> = axiosInstance.get<any, T>(
    fullUrl,
    {
      ...config,
      params: {
        ...params,
        silent,
        service,
        client,
      },
      cancelToken: token,
    },
  )
  request.cancel = cancel
  return request as any
}

function post<T>(
  url,
  data = {},
  silent = false,
  service: null | string = null,
  baseUrl: null | string = null,
) {
  const { token, cancel } = axios.CancelToken.source()
  const fullUrl = createUrl(baseUrl, service, url)
  const request: Partial<CancellablePromise<T>> = axiosInstance.post<any, T>(
    fullUrl,
    data,
    {
      params: {
        silent,
        service,
        version: isMobile ? 1 : 0,
        client,
      },
      cancelToken: token,
    },
  )
  request.cancel = cancel
  return request as any
}

function put<T>(
  url,
  data = {},
  silent = false,
  service: null | string = null,
  baseUrl: null | string = null,
) {
  const { token, cancel } = axios.CancelToken.source()
  const fullUrl = createUrl(baseUrl, service, url)
  const request: Partial<CancellablePromise<T>> = axiosInstance.put<any, T>(
    fullUrl,
    data,
    {
      params: { silent, version: isMobile ? 1 : 0, client },
      cancelToken: token,
    },
  )
  request.cancel = cancel
  return request as any
}

function patch<T>(
  url,
  data = {},
  silent = false,
  service: null | string = null,
  baseUrl: null | string = null,
) {
  const { token, cancel } = axios.CancelToken.source()
  const fullUrl = createUrl(baseUrl, service, url)
  const request: Partial<CancellablePromise<T>> = axiosInstance.patch<any, T>(
    fullUrl,
    data,
    {
      params: { silent, version: isMobile ? 1 : 0, client },
      cancelToken: token,
    },
  )
  request.cancel = cancel
  return request as any
}

function delete_<T>(
  url,
  params = {},
  silent = false,
  service: null | string = null,
  baseUrl: null | string = null,
) {
  const { token, cancel } = axios.CancelToken.source()
  const fullUrl = createUrl(baseUrl, service, url)
  const request: Partial<CancellablePromise<T>> = axiosInstance.delete<any, T>(
    fullUrl,
    {
      params: { ...params, silent, version: isMobile ? 1 : 0, client },
      cancelToken: token,
    },
  )
  request.cancel = cancel
  return request as any
}

export default {
  get,
  put,
  post,
  delete_,
  patch,
}

function createUrl(baseUrl, service, url) {
  let base_url = baseUrl || BASE_URL
  if (service) {
    base_url = platform[service]?.url
    if (service === 'chat') {
      base_url = `https://${base_url}/api/v1`
    }
    if (service === 'livechat') {
      base_url = `https://${platform.chat?.url}/api/v1`
    }
  }
  return `${base_url}/${url}`
}
