export function getUniqueId() {
  return null
}

export function getBundleId() {
  return null
}

export function getVersion() {
  return undefined
}

export function getSystemVersion() {
  return '1'
}

export function getSystemName() {
  return 'web'
}

export function getSystemTheme() {
  let theme = 'light'
  if (
    window.matchMedia &&
    window.matchMedia('(prefers-color-scheme: dark)').matches
  ) {
    theme = 'dark'
  }
  return theme
}

export async function touchIdIsSupported() {
  return false
}

export async function openShare() {
  return null
}

export async function getPhoneNumber() {
  return null
}

export function checkMarketVersion() {
  return Promise.resolve(null)
}
