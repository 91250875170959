import requester from './requester'
import { getClient } from '../utils'

const walletServiceId = 100000

const walletService = {
  blocked: false,
  category_name: 'Внутренний перевод',
  categoryid: walletServiceId,
  commission: null,
  data: {},
  id: walletServiceId,
  image: 'logo-wallet.png',
  image_logo: 'logo-wallet.png',
  keyboard_type: 'default',
  max_amount: 500000.0,
  min_amount: 5.0,
  name: 'Перевод другому платежному агенту',
  service_id: walletServiceId,
  type: 'wallet_service',
}

const requestPost = (url, data, silent = false) => {
  data = { ...data, client: getClient() }
  return requester.post(url, data, silent, 'agent')
}

const agentApi = {
  WALLET_SERVICE_ID: walletServiceId,
  WALLET_SERVICE: walletService,

  getUserInfo(data = {}, silent = false) {
    return requestPost('get_user_info/', data, silent)
  },
  setUserInfo(data = {}, silent = false) {
    return requestPost('edit_user_profile/', data, silent)
  },
  getServices(data = {}, silent = false) {
    return requestPost('services/', data, silent)
  },
  getServicesInfo(data = {}, silent = false) {
    return requestPost('services/general_info/', data, silent)
  },
  getServicesSearch(text, silent = false) {
    return requestPost('services/search/', { text }, silent)
  },
  getServicesBy: function (data = {}, silent = false) {
    return requestPost('services/get/', data, silent)
  },
  getServicesByCountry(data = {}, silent = false) {
    return requestPost('services/by_country/', data, silent)
  },
  getAllServicesByCategory(category_id, silent = false) {
    return requestPost('services/by_parent/', { category_id }, silent)
  },
  getService: function (data = {}, silent = false) {
    return requestPost('service/get/', data, silent)
  },
  getSettings: function (data = {}, silent = false) {
    return requestPost('agent/user.data_get/', data, silent)
  },
  saveSettings: function (data = {}, silent = false) {
    return requestPost('agent/user.data_add/', data, silent)
  },
  getTemplates: function (data = {}, silent = false) {
    return requestPost('agent/user.agent_templates/', data, silent)
  },
  getTemplate: function (id, silent = false) {
    return requestPost('agent/user.get_agent_template/', { id }, silent)
  },
  getSubAgents: function (data = {}, silent = false) {
    return requestPost('agent/user.subagent_list/', data, silent)
  },
  getSubAgentDetails: function (agent_id, silent = false) {
    return requestPost('agent/user.subagent_details/', { agent_id }, silent)
  },
  getActiveSubAgents: function (data = {}, silent = false) {
    return requestPost('agent/user.active_subagent_list/', data, silent)
  },
  getSubAgentsStats: function (data = {}, silent = false) {
    return requestPost('agent/user.subagent_info/', data, silent)
  },
  blockAgent: function (data = {}, silent = false) {
    return requestPost('agent/user.agent_block/', data, silent)
  },
  agentTemplates: function (data = {}, silent = false) {
    return requestPost('agent/user.agent_templates/', data, silent)
  },
  agentTemplate: function (data = {}, silent = false) {
    return requestPost('agent/user.agent_template/', data, silent)
  },
  saveAgent: function (data = {}, silent = false) {
    return requestPost('agent/user.subagent/', data, silent)
  },
  saveTemplate: function (data = {}, silent = false) {
    return requestPost('agent/user.template_add/', data, silent)
  },
  removeTemplate: function (data = {}, silent = false) {
    return requestPost('agent/user.template_remove/', data, silent)
  },
  getCommissions: function (data = {}, silent = false) {
    return requestPost('agent/user.agent_commissions/', data, silent)
  },
  saveCommissions: function (data = {}, silent = false) {
    return requestPost('agent/user.commission_save/', data, silent)
  },
  transfer: function (data = {}, silent = false) {
    return requestPost('transfer/commit/', data, silent)
  },
  internalTransfer: function (data = {}, silent = false) {
    return requestPost('transfer_request/', data, silent)
  },
  getStats: function (data = {}, silent = false) {
    return requestPost('agent/operation.stats/', data, silent)
  },
  getSubHistory: function (data = {}, silent = false) {
    return requestPost('agent/operation.history/', data, silent)
  },
  getHistory: function (data = {}, silent = false) {
    return requestPost('history/', data, silent)
  },
  getHistoryBalance: function (data = {}, silent = false) {
    return requestPost('history/balance/', data, silent)
  },
  payRollback: function (operation_id, silent = false) {
    return requestPost('agent/pay.rollback/', { operation_id }, silent)
  },
  transferRollback: function (operation_id = {}, silent = false) {
    return requestPost('agent/transfer.rollback/', { operation_id }, silent)
  },
  quickPayment: function (data = {}, silent = false) {
    return requestPost('agent/quick/', data, silent)
  },
  updateComments: function (data = {}, silent = false) {
    return requestPost('agent/update_comments/', data, silent)
  },
  partialPaymentDetail: function (data = {}, silent = false) {
    return requestPost('agent/user.partial_payment_detail/', data, silent)
  },
  partialPayments: function (data = {}, silent = false) {
    return requestPost('agent/user.partial_payments/', data, silent)
  },
  savePartialPayment: function (data = {}, silent = false) {
    return requestPost('agent/user.save_partial_payments/', data, silent)
  },
  cancelPartialPayment: function (data = {}, silent = false) {
    return requestPost('agent/user.cancel_partial_payment/', data, silent)
  },
  actRecon: function (data = {}, silent = false) {
    return requestPost('agent/operation.act_recon/', data, silent)
  },
  actBonus: function (data = {}, silent = false) {
    return requestPost('agent/user.bonus_list/', data, silent)
  },
  getNews: function (data = {}, silent = false) {
    return requestPost('news/', data, silent)
  },
  getInfoSettings: function (data, silent = false) {
    return requestPost('info_settings/get/', data, silent)
  },
  getFaq: function (data, silent = false) {
    return requestPost('faq/', data, silent)
  },
  getFavorite(data = {}, silent = false) {
    return requestPost('address_book/get/', data, silent)
  },
  favorites(data = {}, silent = false) {
    return requestPost('address_book/', data, silent)
  },
  requisiteCheck(data) {
    return requestPost('requisite_check/', data)
  },
  paymentCheck(data, silent = false) {
    return requestPost('requisite_check/', data.params, silent)
  },
  getPaymentCommission(data) {
    return requestPost('get_commission/', data)
  },
  pay(data) {
    return requestPost('pay/', data)
  },
  getCheque(guid, operation_id, silent = false) {
    return requestPost('paycheck/', { guid, operation_id }, silent)
  },
  getChequeImage(guid, silent = false) {
    return requestPost('cheque/', { guid }, silent)
  },
  getLastPayments(data = {}, silent = false) {
    return requestPost('payments/last/', data, silent)
  },
  getReport(data = {}, silent = false) {
    return requestPost('agent/user.export_xls/', data, silent)
  },
  jobCheck(job_id) {
    return requestPost('agent/job.check/', { job_id }, true)
  },
  agentFromFile(data) {
    return requester.post('agent/package_pay/', data, true, 'agent')
  },
  agentColaFile(data) {
    return requester.post('agent/coca_cola/package_pay/', data, true, 'agent')
  },
  logout() {
    return requestPost('logout/', {}, true)
  },
  cashOutCheck(data) {
    return requestPost('withdrawal/check/', data)
  },
  cashOut(data) {
    return requestPost('withdrawal/pay/', data)
  },
}

export default agentApi
