export default {
  primary: '#395CA9',
  evion: '#018573',
  secondary: '#F2B00E',
  white: '#ffffff',
  black: '#000',

  red: '#F0604D',
  green: '#8BC34A',
  blue: '#548FDF',
  orange: '#F69F54',
  violet: '#9E85E1',
  midnight: '#6986AA',
  cyan: '#4AB4DC',
  pink: '#E179B1',
  brown: '#987F76',
  yellow: '#FDD857',
  coal: '#59616B',
  pine: '#45C4A1',
  purple: '#7E6A9B',
  lime: '#C5D227',
  mint: '#37DADA',
  rose: '#F08787',
  darkGreen: '#27AE60',
  siren: '#82C3D2',

  darkBlue: '#144764',
  darkGrey: '#333436',
  borderDark: '#4A4B4E',
  metal: '#9DA5AF',
  silver: '#DBDFE2',
  borderLight: '#e5e5e5',
  lightGrey: '#f5f5f5',
  sceletonBg: '#edf1f7',
}
