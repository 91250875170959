import agentApi from '../api/agent'
import walletApi from '../api/wallet'
import LocalStorage from './LocalStorage'
import {
  AgentAllServicesModel,
  AgentCategoryModel,
  IAgentAllServicesModel,
} from './models/Agent'
import { getSnapshot, types } from 'mobx-state-tree'

export default types
  .model({
    categories: types.array(AgentCategoryModel),
    category: types.union(types.maybe(AgentCategoryModel), types.frozen()),
    services: types.array(AgentAllServicesModel),
    service: types.maybeNull(AgentAllServicesModel),
    isAgent: false,
  })
  .actions((self) => ({
    setCategories(categories) {
      self.categories = categories
    },
    setServices(services) {
      self.services = services
    },
    setCategory(category) {
      if (!category) return
      self.category = getSnapshot(category)
    },
    setService(service) {
      if (!service) return
      self.service = service
    },
    getApi() {
      return self.isAgent ? agentApi : walletApi
    },
    subCategory(id) {
      return self.categories.filter((c) => c.parent === id)
    },
  }))
  .actions((self) => ({
    async setCategoryService(
      categoryId: number | null | string = null,
      serviceId: number | null | string = null,
    ) {
      categoryId =
        typeof categoryId === 'string' ? parseInt(categoryId, 10) : categoryId
      serviceId =
        typeof serviceId === 'string' ? parseInt(serviceId, 10) : serviceId

      if (self.categories && self.categories.length > 0) {
        if (categoryId !== null) {
          const category = self.categories.find((c) => c.id === categoryId)
          self.setCategory(category)

          if (self.subCategory(categoryId).length <= 0) {
            const key = self.isAgent ? 'agentServices' : 'services'
            const storage = await LocalStorage.get(key + categoryId)
            if (storage) {
              self.setServices(storage)
              self
                .getApi()
                .getServices({ category_id: categoryId }, true)
                .then((response) => {
                  const services = response.services
                    ?.filter((service) => !service.is_down && !service.blocked)
                    .sort((a, b) => a.name.localeCompare(b.name))
                  LocalStorage.set(key + categoryId, services)
                  self.setServices(services)
                })
            } else {
              const response = await self
                .getApi()
                .getServices({ category_id: categoryId })
              const services = response.services
                ?.filter((service) => !service.is_down && !service.blocked)
                .sort((a, b) => a.name.localeCompare(b.name))
              await LocalStorage.set(key + categoryId, services)
              self.setServices(services)
            }
          }
        }

        if (serviceId !== null) {
          const response = await self
            .getApi()
            .getService({ service_id: serviceId })
          const service = response.service
          self.setService(service)
          const category = self.categories.find(
            (c) => c.id === service?.categoryid,
          )
          self.setCategory(category)
        }
        return true
      }
      return false
    },
  }))

  .actions((self) => ({
    async fetchAllServices(
      categoryId: number | null = null,
      serviceId: number | null = null,
      fromStore = true,
    ) {
      const exists = await self.setCategoryService(categoryId, serviceId)
      if (exists) return

      const storeKey = self.isAgent ? 'agentCategories' : 'categories'
      let storage = await LocalStorage.get(storeKey)
      const fetched = storage?.categories?.length > 0
      if (fetched) {
        self.setCategories(storage.categories)
        self.setCategoryService(categoryId, serviceId)
        if (fromStore) return
      }
      const response = await self
        .getApi()
        .getServices({ only_categories: true }, fetched)
      const categories = response.categories.sort(
        (a, b) => a.sorter_id - b.sorter_id,
      )

      self.setCategories(categories)

      await LocalStorage.set(storeKey, { categories })

      self.setCategoryService(categoryId, serviceId)
    },
  }))
  .actions((self) => ({
    async getSubCategories(categoryId: number | null | string = null) {
      categoryId =
        typeof categoryId === 'string'
          ? parseInt(categoryId || '0', 10)
          : categoryId
      await self.fetchAllServices(categoryId, null, true)
      return self.categories.filter((c) => c.parent === categoryId)
    },

    async getCategoryServices(categoryId: number | null | string = null) {
      categoryId =
        typeof categoryId === 'string'
          ? parseInt(categoryId || '0', 10)
          : categoryId
      await self.fetchAllServices(categoryId, null, true)
      const services = getSnapshot(self.services)
      return services.filter((s) => s.categoryid === categoryId)
    },

    async getCategory(categoryId: number | null | string = null) {
      if (!categoryId) return null
      const id =
        typeof categoryId === 'string' ? parseInt(categoryId, 10) : categoryId
      await self.fetchAllServices(id, null, true)
      return self.category
    },

    async getService(serviceId: number | null | string = null) {
      if (!serviceId) return null
      const id =
        typeof serviceId === 'string' ? parseInt(serviceId, 10) : serviceId
      if (!self.isAgent && id === walletApi.WALLET_SERVICE_ID) {
        return walletApi.WALLET_SERVICE as unknown as IAgentAllServicesModel
      }

      if (self.isAgent && id === agentApi.WALLET_SERVICE_ID) {
        return agentApi.WALLET_SERVICE as unknown as IAgentAllServicesModel
      }
      await self.fetchAllServices(null, id, true)
      if (!self.service) {
        const response = await self
          .getApi()
          .getService({ service_id: serviceId })
        const service = response.service
        self.setService(service)
        if (self.categories.length > 0) {
          const category = self.categories.find(
            (c) => c.id === service?.categoryid,
          )
          self.setCategory(category)
        }
      }
      return self.service
    },
  }))
  .views((self) => ({
    get parentCategories() {
      return self.categories.filter((c) => c.parent === 0)
    },
    get subCategories() {
      if (!self.category) return self.categories.filter((c) => c.parent === 0)
      return self.categories.filter((c) => c.parent === self.category?.id)
    },
    get categoryServices() {
      if (!self.category) return []
      return self.services.filter((s) => s.categoryid === self.category?.id)
    },
  }))
  .named('Document')
