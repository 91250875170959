import { configure, makeAutoObservable } from 'mobx'
import { admin } from '../api'

configure({
  enforceActions: 'never',
})

class adminStore {
  adminOTPRequired = false

  verificationCode = ''

  verificationMessage = ''

  verified = false

  constructor() {
    makeAutoObservable(this)
  }

  get isAdminOTPRequired() {
    return this.adminOTPRequired
  }

  setAdminOTPRequired(value) {
    this.adminOTPRequired = value
  }

  async check(page_id, action = 'read') {
    return admin.check(page_id, action)
  }

  async verify() {
    this.verificationMessage = ''
    let r = await admin.verify(this.verificationCode)
    this.verified = r.verified
    if (this.verified) {
      this.verificationMessage =
        'Вы верифицированы. Можете повторить свой запрос'
      setTimeout(() => this.clear(), 1000)
    } else {
      this.verificationMessage = 'Вы ввели неправильный код. Попробуйте еще раз'
    }
  }

  clear() {
    this.setAdminOTPRequired(false)
    this.verificationMessage = ''
    this.verificationCode = ''
  }
}

const AdminStore = new adminStore()

export default AdminStore
