import requester from '../api/requester'
import { getClient } from '../utils'
import { i18n } from '../translate'
import { INTERNAL_TRANSFER_WALLET_ID } from '../constants'

const requestPost = (url, data, silent) => {
  data = { ...data, client: getClient() }
  return requester.post(url, data, silent, 'wallet')
}

const walletService = {
  blocked: false,
  category_name: i18n.t('internal_transfer'),
  contact_data: {},
  categoryid: INTERNAL_TRANSFER_WALLET_ID,
  commission: null,
  data: {},
  id: INTERNAL_TRANSFER_WALLET_ID,
  image: 'logo-wallet.png',
  image_logo: 'logo-wallet.png',
  keyboard_type: 'default',
  max_amount: 500000.0,
  min_amount: 5.0,
  name: 'Перевод другому пользователю ',
  service_id: INTERNAL_TRANSFER_WALLET_ID,
  type: 'wallet_service',
}

const walletApi = {
  WALLET_SERVICE_ID: INTERNAL_TRANSFER_WALLET_ID,
  WALLET_SERVICE: walletService,
  getServices(data = {}, silent = false) {
    return requestPost('services/', data, silent)
  },
  getServicesBy(data = {}, silent = false) {
    return requestPost('services/get/', data, silent)
  },
  getServicesSearch(text = '', data = {}, silent = false) {
    return requestPost('services/search/', text ? { text } : data, silent)
  },
  getService: function (data = {}, silent = false) {
    return requestPost('service/get/', data, silent)
  },
  getAllServicesByCategory(category_id, silent) {
    return requestPost('services/by_parent/', { category_id }, silent)
  },
  favorites(data = {}, silent) {
    return requestPost('address_book/', data, silent)
  },
  getHotServices(data = {}, silent) {
    return requestPost('service/favorites/', data, silent)
  },
  getDepositableServices(data = {}, silent = false) {
    return requestPost('services/by_props/', data, silent)
  },
  paymentCheck(data = {}, silent) {
    return requestPost('payment/check', data, silent)
  },
  getLastPayments(data = {}, silent) {
    return requestPost('payments/last', data, silent)
  },
  saveAutoPayment(data = {}, silent) {
    return requestPost('auto_payment/save', data, silent)
  },
  checkRequisite(data = {}, silent) {
    return requestPost('requisite_check/', data, silent)
  },
  getHistory(url, data = {}, silent) {
    return requestPost(url, data, silent)
  },
  logout() {
    return requestPost('logout/', {}, true)
  },
}

export default walletApi
