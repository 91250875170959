import requester, { BASE_URL } from '../api/requester'
import { sleep } from '../utils'

const requestPost = (url, data = {}, silent = false) => {
  return requester.post(url, data, silent, 'pay24')
}

let cancelPayment = true

const pay24Api = {
  getUserInfo(data = {}) {
    return requestPost('user_info', data)
  },
  getProperties(data = {}) {
    return requestPost('market/properties', data)
  },
  getPropertiesByCategory(category_id, category_type_id?: string) {
    return requestPost('market/properties_by_category', {
      category_id,
      category_type_id,
    })
  },
  getPropertiesAdmin(data = {}) {
    return requestPost('market/properties_admin', data)
  },
  getCategoryTree(categoryTypeId, data: any = {}) {
    data.category_type_id = categoryTypeId
    return requestPost('market/category_tree', data)
  },
  getCategories(categoryTypeId, data: any = {}) {
    data.category_type_id = categoryTypeId
    return requestPost('market/categories', data)
  },
  getSubscribesList(data: any = {}) {
    return requestPost('followeds', data)
  },
  getFollowersList(data: any = {}) {
    return requestPost('followers', data)
  },
  getCategoriesAdmin(data = {}) {
    return requestPost('market/categories_admin', data)
  },
  getCountries(lang = 'ru') {
    return requestPost('market/countries?lang=' + lang)
  },
  getRegions(country_id = null) {
    return requestPost('market/regions', { country_id: country_id })
  },
  getDistricts(region_id = null) {
    return requestPost('market/districts', { region_id: region_id })
  },
  getCurrencies() {
    return requestPost('market/currencies')
  },
  getFileTypes() {
    return requestPost('market/ad_file_types')
  },
  getShopTypes() {
    return requestPost('shop_types')
  },
  getWorkingHours() {
    return requestPost('working_hours')
  },
  getRoles: function (only_common = true) {
    return requestPost('roles', { only_common })
  },
  searchUsers: function (search) {
    return requestPost('user_search', { search })
  },
  saveAd: function (data = {}) {
    return requestPost('market/save_ad', data)
  },
  getAd(ad_id, forEdit = false) {
    return requestPost('market/ad', { ad_id: ad_id, for_edit: forEdit })
  },
  searchAds(data = {}) {
    return requestPost('market/ads', data)
  },
  getSubscribeAds(data) {
    return requester.post('market/subscribe_ads', data)
  },
  getAdsAdmin(data = {}) {
    return requestPost('market/ads_admin', data)
  },
  popularAds(items_per_page, page_number = 1) {
    return requestPost('market/popular_ads', {
      query: {},
      items_per_page,
      page_number,
    })
  },
  imageURL(ad_id, path, size) {
    return `${BASE_URL}/market/files/get/?ad_id=${ad_id}&filename=${path}&size=${size}`
  },
  storeImageURL(store_id, path, size) {
    return `${BASE_URL}/market/store_files/get/?store_id=${store_id}&filename=${path}&size=${size}`
  },
  saveProperty(data = {}) {
    return requestPost('market/save_property', data)
  },
  deleteProperty(property_id) {
    return requestPost('market/delete_property', { property_id: property_id })
  },
  saveCategory(data = {}) {
    return requestPost('market/save_category', data)
  },
  deleteCategory(category_id) {
    return requestPost('market/delete_category', { category_id: category_id })
  },
  getMyStores() {
    return requestPost('market/my_stores', {})
  },
  async downloadAccounting(filename: string) {
    return requester.get(
      'admin/get/files/accounting',
      { filename },
      false,
      null,
      null,
      { responseType: 'blob' },
    )
  },
  getFavoriteStores(query) {
    return requestPost('market/favorite_stores', { query })
  },
  searchStores() {
    return requestPost('market/stores', {})
  },
  getStore(store_id) {
    return requestPost('market/store', { store_id })
  },
  getOwnerStore(store_id) {
    return requestPost('market/owner_store', { store_id })
  },
  saveStore(data = {}) {
    return requestPost('market/save_store', data)
  },
  getAdMessages(data = {}) {
    return requestPost('market/ad_messages', data)
  },
  saveAdMessage(data = {}) {
    return requestPost('market/save_ad_message', data)
  },
  getAdReviews(data = {}) {
    return requestPost('market/ad_reviews', data)
  },
  saveAdReview(data = {}) {
    return requestPost('market/save_ad_review', data)
  },
  deleteAdMessage(data = {}) {
    return requestPost('market/delete_ad_message', data)
  },
  getUserRating(user_id) {
    return requester.get(`market/user_rating/${user_id}`)
  },
  getAdRating(ad_id) {
    return requester.get(`market/ad_rating/${ad_id}`)
  },
  deleteAdReview(data = {}) {
    return requestPost('market/delete_ad_review', data)
  },
  deleteAd(data = {}) {
    return requestPost('market/delete_ad', data)
  },
  changeAdStatus(data = {}) {
    return requestPost('market/change_ad_status', data)
  },
  toggleAdFavorite(ad_id) {
    return requestPost('market/toggle_ad_favorite', { ad_id })
  },
  toggleStoreFavorite(store_id) {
    return requestPost('market/toggle_store_favorite', { store_id })
  },
  getAdFavoriteIds() {
    return requestPost('market/ad_fav_ids', {})
  },
  getStoreFavoriteIds() {
    return requestPost('market/store_fav_ids', {})
  },
  getStoreMessages(data = {}) {
    return requestPost('market/store_messages', data)
  },
  saveStoreMessage(data = {}) {
    return requestPost('market/save_store_message', data)
  },
  deleteStoreMessage(data = {}) {
    return requestPost('market/delete_store_message', data)
  },
  getPaymentTypes() {
    return requester.post('payment/types', {}, true)
  },
  paymentCheck(data, silent = false) {
    return requester.post('payment/check', data, silent)
  },
  paymentStart(data, silent = false) {
    return requester.post('payment/start', data, silent)
  },
  bill() {
    return requester.get('bill/')
  },
  payBill(data, silent = false) {
    return requester.post('bill/pay', data, silent)
  },
  getDebitAccounts(data, silent = false) {
    return requester.get('payment/accounts/debit', data, silent)
  },
  getCreditAccounts(data, silent = false) {
    return requester.get('payment/accounts/credit', data, silent)
  },
  postPayByHold(data, silent = false) {
    return requester.post('payment/paybyhold', data, silent)
  },
  paymentPoll: async function (id) {
    cancelPayment = false
    let data: any = null
    do {
      await sleep(3000)
      data = await requester.get(`payment/status/${id}`, undefined, true)
    } while (!cancelPayment && data?.status === 'pending')
    return data
  },
  cancelPaymentPoll() {
    cancelPayment = true
  },
  login(data, silent = false) {
    return requester.post('login', data, silent)
  },
  register(phone_number, password, verification_code) {
    return requester.post(
      'register',
      { phone_number, password, verification_code },
      false,
    )
  },
  verify(phone_number, sms_text, recaptcha_token?: string) {
    return requester.post(
      'verify',
      { phone_number, sms_text, recaptcha_token },
      false,
    )
  },
  getCheque(guid, operation_id, silent) {
    return requester.post('paycheck', { guid, operation_id }, silent)
  },
  otpRequest(phonenumber, silent) {
    return requester.post(
      'settings/phone/sendConfirmCode',
      { phonenumber },
      silent,
    )
  },
  otpConfirm(code, silent) {
    return requester.post('settings/phone/update', { code }, silent)
  },
  emailCodeRequest(email, silent) {
    return requester.post('settings/email/sendConfirmCode', { email }, silent)
  },
  emailCodeConfirm(code, silent) {
    return requester.post('settings/email/update', { code }, silent)
  },
  getNotificationSettings() {
    return requester.post('notifications/get')
  },
  updateNotifications(params) {
    return requester.post('notifications/edit', { params })
  },
  updatePhoneNumber() {
    return requester.post('update_phone')
  },
  financialRecords(data = {}) {
    return requester.post('finance/history', data)
  },
  getHeldRecords() {
    return requester.get('finance/held_records', {}, false)
  },
  getHeldRecordsDetail(id) {
    return requester.get(`finance/${id}/related_records`, {}, false)
  },
  getLastRecords(data) {
    return requester.post('finance/history/last', data, true)
  },

  subscribe(data) {
    return requester.post('subscribe', data)
  },
  blockUserAd(data = {}) {
    return requester.post('add_block_user', data)
  },
  deleteBlockUserAd(data = {}) {
    return requester.post('delete_block_user', data)
  },
  getBlockedUsers(data = {}) {
    return requester.post('block_user_list', data)
  },
  unsubscribe(data) {
    return requester.post('unsubscribe', data)
  },

  getUsers(data) {
    return requester.post('users', data)
  },

  getUserMarket(user_id) {
    return user_id ? requester.get(`user/market/${user_id}`) : null
  },

  checkSubscribe(data) {
    return requester.post('subscribe/check', data)
  },

  getFollowers(data) {
    return requester.post('followers', data)
  },

  /**
   * Forwards deposit to bank account
   * @param records: array of objects with record_id and amount
   * @returns {Promise<unknown>}
   */
  forwardDeposit(records) {
    return requester.post('finance/forward', { records })
  },
  recordDetails(data) {
    return requester.post('finance/details', data)
  },
  debitAccountAddStart(type, data) {
    return requester.get(`accounts/add`, { id: type, ...data })
  },
  getFavorites(data: any = null, silent = false) {
    return requester.get('payment/favorites', data, silent)
  },
  saveFavorite(favorite) {
    return requester.post('payment/favorite', favorite)
  },
  deleteFavorite(id) {
    return requester.delete_('payment/favorite', { id })
  },
  getDocumentsInvoices(data, silent = false) {
    return requester.post('documents/invoices', data, silent)
  },
  cancelInvoiceDocument(id, silent = false) {
    return requester.patch('documents/invoice/cancel', { id }, silent)
  },
  getUniversityBill(silent = false) {
    return requester.get(`university/bill`, {}, silent)
  },
  createCompany(data) {
    return requester.post('erp/register', data)
  },
  checkCompany(id) {
    return requester.get('erp/check/' + id, undefined, true)
  },
  getCompanies() {
    return requester.get('erp/get-companies', {}, true)
  },
  getAdminPayments(data) {
    return requester.post('admin/history', data)
  },
  getAdminStats(data) {
    return requester.post('admin/get-payment-statistics', data)
  },
  sendToHold: function (id) {
    return requester.post('admin/hold', { id })
  },
  getAdminHistoryById: function (id) {
    return requester.post(`admin/history/${id}`)
  },
  getJobCheck(data) {
    return requester.post('admin/job_check', data)
  },
  getAdminHistoryFile(file) {
    return requester.get('admin/get/files/accounting', { filename: file })
  },
  getAdminPaymentUser(data) {
    return requester.post('admin/user-details', data)
  },
  getAdminRelated(data) {
    return requester.post('admin/related_payments', data)
  },
  adminBlockUser(data) {
    return requester.post('admin/toggle-block', data)
  },
  checkLimits(service_id, amount) {
    return requester.post('check_limit', { service_id, amount })
  },
  saveParam(param) {
    return requester.post('params/save', param)
  },
  getParams() {
    return requester.post('params/get', {})
  },
  getParam(param) {
    return requester.get(`params/get/${param}`, {})
  },
  emasList(search?: string, emas = false) {
    return requester.get('university/student', { search, emas })
  },
  emasUpdate(data) {
    return requester.patch('university/student', data)
  },
  emasDeleteStudent(phone: string) {
    return requester.delete_('university/student', { username: phone })
  },
  getComplaintsReasons(reason_type: string) {
    return requester.get(`market/get_complaints_reasons/${reason_type}`)
  },
  addComplaint(data) {
    return requester.post('market/add_complaint/', data)
  },
  getAdComplaints(ad_id: string) {
    return requester.get(`market/get_ad_complaints/${ad_id}`)
  },
  changeTerminalGroup(data) {
    return requester.post('payment/terminal_group_change', data)
  },
  getTokenBank(bank_name = 'optima') {
    return requester.get('m4bank/get-token/' + bank_name)
  },
  sendPushMessage(data = {}) {
    return requester.post('admin/send_push_message', data)
  },
}

export default pay24Api
