import { useEffect, useState } from 'react'
import { passport } from '../api'
import constants from '../constants'

type IPosition = {
  latitude: number
  longitude: number
  source: 'api' | 'gps' | 'default' | 'error'
}

interface ILocation extends IPosition {
  loaded?: boolean
}
export const getLocationFromGPS = async () => {
  return new Promise<IPosition>((resolve, reject) => {
    if (!('geolocation' in navigator)) {
      reject('native geolocation is not supported')
    } else {
      navigator.geolocation.getCurrentPosition(
        (location) => {
          resolve({
            longitude: location.coords.longitude,
            latitude: location.coords.latitude,
            source: 'gps',
          })
        },
        reject,
        {
          timeout: 5000,
        },
      )
    }
  })
}

export const getLocationFormApi = async () => {
  return new Promise<IPosition>((resolve, reject) => {
    passport
      .getUserCountry()
      .then((res) => {
        if (res.lat && res.lon) {
          resolve({
            latitude: res.lat,
            longitude: res.lon,
            source: 'api',
          })
        } else {
          reject('')
        }
      })
      .catch((err) => reject(err))
  })
}

export const getLocation = async () => {
  return new Promise<IPosition>((resolve, reject) => {
    getLocationFromGPS()
      .then((position) => resolve(position))
      .catch(() =>
        getLocationFormApi()
          .then((position) => resolve(position))
          .catch((err) => reject(err)),
      )
  })
}

export const useGeoLocation = () => {
  const [location, setLocation] = useState<ILocation>({
    loaded: false,
    latitude: constants.COORDINATES.lat,
    longitude: constants.COORDINATES.lng,
    source: 'default',
  })

  useEffect(() => {
    getLocation()
      .then((position) =>
        setLocation({
          loaded: true,
          latitude: position.latitude,
          longitude: position.longitude,
          source: position.source,
        }),
      )
      .catch(() =>
        setLocation({
          loaded: true,
          latitude: constants.COORDINATES.lat,
          longitude: constants.COORDINATES.lng,
          source: 'error',
        }),
      )
  }, [])

  return location
}
