import requester from '../api/requester'
import LocalStorage from '../store/LocalStorage'

const requestPost = (url, data = {}, silent = false) => {
  return requester.post(url, data, silent, 'passport')
}

const requestPut = (url, data = {}, silent = false) => {
  return requester.put(url, data, silent, 'passport')
}

const requestGet = (url, data = {}, silent = false) => {
  return requester.get(url, data, silent, 'passport')
}

const requestDelete = (url, data = {}, silent = false) => {
  return requester.delete_(url, data, silent, 'passport')
}

const passportApi = {
  setAccount: function (data = {}, silent = false) {
    return requestPut('api/accounts', data, silent)
  },
  addAccount: function (data) {
    return requestPost('api/accounts', data)
  },
  removeAccount: function (id) {
    return requestDelete('api/accounts', { id })
  },
  getUserInfo: function (data, silent = false) {
    return requestGet('api/me', data, silent)
  },
  getUserCountry: function () {
    return requestGet('api/country', {}, true)
  },
  saveProfile: function (data, silent = false) {
    return requestPost('api/me', data, silent)
  },
  saveMessanger: function (data, silent = false) {
    return requestPut('api/identification/video_approve', data, silent)
  },
  getVideoTimesheet: function (data, silent = false) {
    return requestGet('api/identification/available_time', data, silent)
  },
  checkIdentification: function (data, silent = false) {
    return requestPost('api/identification/check', data, silent)
  },
  resetIdentification: function (data, silent = false) {
    return requestPut('api/identification/new', data, silent)
  },
  changePassword: function (
    current,
    password,
    password_confirm,
    silent = false,
  ) {
    return requestPost(
      'api/password_change',
      { current, password, password_confirm },
      silent,
    )
  },
  forgotPassword: function (username, recaptcha_token?: string) {
    return requestPost('api/forgot', { username, recaptcha_token })
  },
  forgotPasswordChange: function (
    username,
    verification_code,
    password,
    password_confirm,
  ) {
    return requestPut('api/forgot', {
      username,
      verification_code,
      password,
      password_confirm,
    })
  },
  loginChangeStep1: function () {
    return requestGet('api/login_change')
  },
  loginChangeStep2: function (code, username) {
    return requestPost('api/login_change', { code, username })
  },
  loginChangeStep3: function (code, username) {
    return requestPut('api/login_change', { code, username })
  },
  emailChangeStep1: function () {
    return requestGet('api/email_change')
  },
  emailChangeStep2: function (code, email) {
    return requestPost('api/email_change', { code, email })
  },
  emailChangeStep3(code, email) {
    return requestPut('api/email_change', { code, email })
  },
  getOtpWaitTime() {
    return requestGet('api/login_change_get_time')
  },
  avatarUpload(data) {
    return requestPost('api/avatar_upload', data)
  },
  passportUpload(data) {
    return requestPost('api/passport_upload', data)
  },
  avatarDelete() {
    return requestDelete('api/avatar_upload')
  },
  async usersExists(contact) {
    let phones = contact.phoneNumbers.map((pn) => {
      return pn.number
    })
    let resp = await requestPost('api/users_exists', { phones })
    return resp.exists.some((n) => n.exists)
  },
  contactDelete(contact) {
    return requestPost('api/contact_delete', {
      givenName: contact.givenName,
      familyName: contact.familyName,
      middleName: contact.middleName,
    })
  },
  chatLogin: function () {
    return requestPost('api/chat_login', {}, true)
  },
  contactToggleBlock(contact) {
    return requestPost('api/contact_toggle_block', {
      givenName: contact.givenName,
      familyName: contact.familyName,
      middleName: contact.middleName,
    })
  },
  blockUser: function (user_id, blocked, reason_text) {
    return requestPost(
      'api/user_toggle_block',
      { user_id, blocked, reason: reason_text },
      true,
    )
  },
  blockInfo: function (user_id) {
    return requestGet('api/block_info', { user_id })
  },
  getHolidays: function () {
    return requestGet('', {}, true)
  },
  async savePushToken(token, info) {
    return requestPost('api/push_token', { token, info }, true)
  },
  async logout() {
    return requestPost('api/logout', {}, true)
  },

  async deletePushToken() {
    let token = await LocalStorage.get('push_token')
    if (token) {
      LocalStorage.remove('push_token')
      return requestDelete('api/push_token', { token }, true)
    }
  },
}

export default passportApi
